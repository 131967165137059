<script>
import mini from "../assets/images/mini.png";
import gz from "../assets/images/gz.png";
export default {
  name: "FooterComp",
  render() {
    return (
      <footer>
        <div class="container">
          <div class="linksBox">
            <div class="list">
              <a href="javascript:;" class="active" style={{cursor: "default"}}>
                合作链接
              </a>
              <a href="https://www.eic.org.cn/">启德教育</a>
              <a href="https://www.eickaopei.com/">启德考培</a>
              <a href="http://studytour.eic.org.cn/">启德学游</a>
            </div>
            <div class="care" />
          </div>
          <div class="infoBox">
            <div class="logoBox">
              <div class="group">
                <i class="icon-logo" />
                <div class="urlBox">
                  <i class="text" />
                  <i class="url" />
                </div>
              </div>
              <p>
                Copyright © 2020 广东启德教育服务有限公司 All Rights Reserved
              </p>
							<p><a href="https://beian.miit.gov.cn/" target="_blank" style="color:#a4aab3;text-decoration:none">鲁ICP备2022002161号-1</a> | <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37010202002901" style="display:inline-block;color:#a4aab3;text-decoration:none"><img src='https://www.eic.org.cn/Content/Images/beian.png' style="display:block;width:15px;margin-top:3px;margin-right:5px;float:left;" />鲁公网安备 37010202002901号</a>
              </p>
            </div>
            <div class="qrCodeBox">
              <div class="mini">
                <p>启德课堂小程序</p>
                <img src={mini} />
              </div>
              <div class="gz">
                <p>启德课堂公众号</p>
                <img src={gz} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  },
};
</script>
<style lang="less" scoped>
footer {
  background: #262626;
  .container {
    
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;
    .linksBox {
      display: flex;
      height: 60px;
      align-items: center;
      border-bottom: 1px solid #62666a;
      justify-content: space-between;
      .list {
        display: flex;
        align-items: center;
        a {
          font-size: 16px;
          color: #ffffff;
          font-weight: 400;
          margin-right: 48px;
          text-decoration: none;
          line-height: 18px;
        }
        a.active {
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
        }
      }
      .care {
        width: 101px;
        height: 27px;
        background-image: url("../assets/images/care.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    .infoBox {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      .logoBox {
        display: flex;
        flex-direction: column;
        .group {
          display: flex;
          align-items: center;
          .icon-logo {
            display: block;
            width: 90px;
            height: 67px;
            background-image: url("../assets/images/footer_logo.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 26px;
          }
          .urlBox {
            display: flex;
            flex-direction: column;
            .text {
              display: block;
              width: 322px;
              height: 22px;
              background-image: url("../assets/images/footer_text.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .url {
              display: block;
              width: 247px;
              height: 18px;
              background-image: url("../assets/images/footer_url.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-top: 9px;
            }
          }
        }
        p {
          font-size: 13px;
          font-weight: 400;
          color: #a4aab3;
          line-height: 20px;
          margin-top: 18px;
        }
      }
      .qrCodeBox {
        display: flex;
        .mini {
          display: flex;
          align-items: center;
          margin-right: 31px;
          p {
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            color: #ffffff;
            font-weight: 400;
            font-size: 13px;
            margin-right: 7px;
          }
          img {
            width: 101px;
            height: 101px;
          }
        }
        .gz {
          display: flex;
          align-items: center;
          p {
            -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
            color: #ffffff;
            font-weight: 400;
            font-size: 13px;
            margin-right: 7px;
          }
          img {
            width: 101px;
            height: 101px;
          }
        }
      }
    }
  }
}
</style>