import * as api from "@src/service/index";
export default {
	namespaced: true,
	state: {
		isShowLoading: false,
		loginState: "",
		isShowLogin: false,
		countryCodeList: [], // 国家区号列表
		countryList: [], // 国家列表
		vxAuthorData: {}, // 微信授权信息
		openid: "",
		access_token: "",
		unionid: "",
		registerForm: {
			mobile: "", // 手机号
			nickname: "", // 昵称
			code: "", //验证码
			password: "", //密码
			countryId: "", // 国家ID
			countryCode: "", // 国家区号
			clientType: 3,
			channelmed: "", // 推广渠道
			localUrl: "", // 推广原始url
		},
		loginForm: {
			countryCode: "", // 国家区号
			mobile: "", // 手机号
			code: "", // 验证码
			password: "", //密码
		},
	},
	mutations: {
		updateState(state, payload) {
			Object.keys(payload).map((key) => {
				state[key] = payload[key];
			});
		},
	},
	actions: {
		// 获取国家区号列表
		async getCountryCodeList(content, payload) {
			const {
				loginForm,
				registerForm
			} = content.state;
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				results,
				succ,
				msg
			} = await api.getCountryCodeList();
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			content.commit("updateState", {
				countryCodeList: results,
				loginForm: {
					...loginForm,
					countryCode: results[0].code,
				},
				registerForm: {
					...registerForm,
					countryCode: results[0].code,
				},
			});
			return Promise.resolve();
		},

		// 获取国家列表
		async getCountryList(content, payload) {
			const {
				registerForm
			} = content.state;
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				results,
				succ,
				msg
			} = await api.getCountryList();
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			content.commit("updateState", {
				countryList: results,
				registerForm: {
					...registerForm,
					countryId: results[0].code,
				},
			});
			return Promise.resolve(msg);
		},
		// 发送短信验证码
		async sendCode(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				msg
			} = await api.sendCode(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			return Promise.resolve(msg);
		},

		// 登录
		async userLogin(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				msg,
				results
			} = await api.userLogin(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			window.$cookies.set("token", results.token);
			window.$cookies.set("accountId", results.id);
			return Promise.resolve(msg);
		},
		// 注册
		async userRegister(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				results,
				msg
			} = await api.userRegister(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			window.$cookies.set("token", results.token);
			window.$cookies.set("accountId", results.id);
			return Promise.resolve(msg);
		},
		async userForget(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				results,
				msg
			} = await api.userForget(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject(msg);
			return Promise.resolve(msg);
		},
		// 检查验证码
		async checkCode(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				results,
				msg,
				code
			} = await api.checkCode(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject({
				code,
				msg
			});
			return Promise.resolve(msg);
		},
		// 获取微信授权参数
		async getAuthorizeParam(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				results,
				msg,
				code
			} = await api.getAuthorizeParam(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject({
				code,
				msg
			});
			content.commit("updateState", {
				vxAuthorData: results,
			});
			return Promise.resolve(msg);
		},

		// 获取AccessToken
		async getAccessToken(content, payload) {
			content.commit("updateState", {
				isShowLoading: true
			});
			const {
				succ,
				results,
				msg,
				code
			} = await api.getAccessToken(payload);
			content.commit("updateState", {
				isShowLoading: false
			});
			if (!succ) return Promise.reject({
				code,
				msg
			});
			window.$cookies.set("openId", results.openid);
			content.commit("updateState", {
				openid: results.openid,
				access_token: results.access_token,
				unionid: results.unionid
			});
			return Promise.resolve(msg);
		},

		//用户行为埋点
		async recordBehavior(content, payload) {
			content.commit("updateState", {
				isLoadingShow: true
			});
			const {
				results,
				succ,
				msg
			} = await api.recordBehavior(payload);
			content.commit("updateState", {
				isLoadingShow: false
			});
			if (!succ) return Promise.reject(msg);
			content.commit("updateState", {
				recordBehavior: ''
			});
			return Promise.resolve();
		},
	},
};
