<script>
export default {
  name: "reset",
  props: {
    toggleNowPage: {
      type: Function,
    },
  },
  data() {
    return {
      formData: {
        countryCode: "",
        mobile: "",
        password: "",
        code: "",
      },
      errorData: {
        phone: false,
        pwd: false,
        againpwd: false,
      },
      isAuthCodeClick: false,
      time: 60,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("LoginModel/getCountryCodeList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const { countryCodeList } = this.$store.state.LoginModel;
    this.formData.countryCode = countryCodeList[0].code;
  },
  methods: {
    // 区号选择事件
    onSelectChagne(e) {
      const { value } = e.target;
      this.formData.countryCode = value;
      this.areaCode = e.target.value;
    },
    // 手机号输入事件
    onPhoneInput(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "");
      const { value } = e.target;
      this.phone = e.target.value;
      if (value !== "") {
        this.errorData.phone = false;
        return;
      }
      this.formData.mobile = value;
      // this.errorData.phone = !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
    },

    onPwdInput(e) {
      e.target.value = e.target.value.replace(/[^0-9A-Za-z]/g, "");
      const { value } = e.target;
      if (value === "") {
        this.errorData.pwd = false;
        return;
      }
      this.formData.password = value;
      this.errorData.pwd = !/^[A-Za-z0-9~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]]{8,20}$/.test(value);
    },
		
		// 点击获取验证码
		async onAuthCodeClick() {
			if (!this.formData.mobile) return (this.errorData.phone = true);
			this.getGraphicVerification(this.handlerVerify)
		},

		async handlerVerify(ev) {
			console.log(ev.detail, "ev.detail")
			if (ev.detail.ret === 0) {
				// 获取验证码
				try {
					await this.$store.dispatch("LoginModel/sendCode", {
						...this.formData,
						type: "forget",
						ticket: ev.detail.ticket,
						randstr: ev.detail.randstr,
						clentType: 2,
					});
				} catch (msg) {
					this.$message({
						showClose: true,
						message: msg,
						type: "error"
					});
					return;
				}

				this.isAuthCodeClick = true;

				const timer = setInterval(() => {
					if (this.time === 1) {
						this.isAuthCodeClick = false;
						this.time = 60;
						clearInterval(timer);
					} else {
						this.time = this.time - 1;
					}
				}, 1000);
			} else {
				// 验证失败
				// 请不要在验证失败中调用refresh，验证码内部会进行相应处理
			}
		},
    onAgainPwdInput(e) {
      const { value } = e.target;
      if (value !== this.formData.password) {
        this.errorData.againpwd = true;
      } else {
        this.errorData.againpwd = false;
      }
    },
    onCodeInput(e) {
      const { value } = e.target;
      this.formData.code = value;
    },

    async submit() {
      try {
        await this.$store.dispatch("LoginModel/userForget", this.formData);
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$store.commit("LoginModel/updateState", {
        loginState: "login",
      });
    },
  },
  render() {
    const { countryCodeList } = this.$store.state.LoginModel;
    return (
      <div class="reset-box">
        <h1>重置密码</h1>
        <div class="form-box">
          <div class={`input-box ${this.errorData.phone ? "error" : ""}`}>
            <select onchange={(e) => this.onSelectChagne(e)}>
              {countryCodeList.map((item) => (
                <option value={item.code}>+{item.code}</option>
              ))}
            </select>
            <input
              class="phone"
              placeholder="请输入手机号"
              style={{ width: "50%" }}
              oninput={this.onPhoneInput}
            />
            {this.errorData.phone ? (
              <span class="error">
                <i class="icon info" />
                手机号不能为空!
              </span>
            ) : null}
          </div>
          <div class="input-box" style={{ justifyContent: "space-between" }}>
            <input placeholder="请输入验证码" oninput={this.onCodeInput} />
            {this.isAuthCodeClick ? (
              <span class="btn gray">重新获取({this.time})</span>
            ) : (
              <span class="btn" onclick={this.onAuthCodeClick}>
                获取验证码
              </span>
            )}
          </div>
          <div class={`input-box ${this.errorData.pwd ? "error" : ""}`}>
            <input
              type="password"
              placeholder="请输入密码"
              style={{ width: "100%" }}
              oninput={this.onPwdInput}
              maxLength="16"
            />
            {this.errorData.pwd ? (
              <span class="error">
                <i class="icon info" />
                密码格式错误
              </span>
            ) : null}
          </div>
          <div class={`input-box ${this.errorData.againpwd ? "error" : ""}`}>
            <input
              type="password"
              placeholder="请再次输入密码"
              style={{ width: "80%" }}
              oninput={this.onAgainPwdInput}
            />
            {this.errorData.againpwd ? (
              <span class="error">
                <i class="icon info" />
                两次密码输入不同
              </span>
            ) : null}
          </div>
        </div>
        <div class="btn-group">
          <span class="btn" onclick={this.submit}>
            完成
          </span>
          <span
            class="text"
            onclick={() =>
              this.$store.commit("LoginModel/updateState", {
                loginState: "login",
              })
            }
          >
            返回登录
          </span>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.reset-box {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  box-sizing: border-box;
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 45px;
  }
  .form-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .input-box {
      height: 40px;
      background: #f3f6fa;
      border-radius: 8px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .error {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #f84164;
        width: 100%;
        .icon.info {
          width: 15px;
          height: 15px;
          background-image: url("../../../assets/images/error-info.png");
          margin-right: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      select {
        background: none;
        border: 0;
        outline: none;
        cursor: pointer;
        margin-right: 10px;
        height: 24px;
      }
      input {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
      }
      input.phone {
        line-height: 24px;
        border-left: 1px solid #c8cdd6;
      }
      span.btn {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #006eeb;
      }
      span.btn.gray {
        color: #a4aab3;
        pointer-events: none;
      }
    }
    .input-box.error {
      background: #fee3e8;
      input {
        color: #f94063;
      }
      span.error {
        color: #f84164;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    .btn {
      width: 100%;
      height: 40px;
      background: #006eeb;
      border-radius: 8px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .text {
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
      color: #006eeb;
      cursor: pointer;
    }
  }
}
</style>