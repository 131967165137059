import * as api from "@src/service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    swiperList: [], // 轮播图
    liveList: [], // 近期直播列表
    hotLiveList: [], // 热门直播列表
    teacherOnlineList: [], // 名师在线列表
    studentShareList: [], // 学子分享直播列表
    hotRecordList: [], // 最热回放列表
    newRecordList: [], // 最新回放列表
    userSubLiveList: [], // 用户预约直播列表
    userLiveBackList: [], // 用户直播回放列表
    studentShareLists: [], // 学子分享（全部）
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取首页banner图
    async getPageInfoList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getPageInfoList({ id: 128 });
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const arr = [];
      results.pageInfo.map(
        (item) => item.pageInfoTypeId === 130 && arr.push(item)
      );
      content.commit("updateState", {
        swiperList: arr,
      });
      return Promise.resolve(msg);
    },
    //获取近期直播列表
    async getRecentLive(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getRecentLive(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { liveList: results });
      return Promise.resolve(msg);
    },
    // 获取附近直播
    async getLiveListByIp(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveListByIp(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { hotLiveList: results });
      return Promise.resolve(msg);
    },
    // 获取热门直播
    async getHotLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveForeShow(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { hotLiveList: results });
      return Promise.resolve(msg);
    },
    //获取最热回放列表
    async getHotRecordList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveBackList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { hotRecordList: results });
      return Promise.resolve(msg);
    },
    // 获取最新回放列表
    async getNewRecordList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveBackList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { newRecordList: results });
      return Promise.resolve(msg);
    },
    // 获取名师在线列表
    async getTeacherOnlineLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getTeacherOnlineLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { teacherOnlineList: results });
      return Promise.resolve(msg);
    },
    // 获取学子分享列表
    async getStudentShareLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getStudentShareLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const studentShareList = [];
      results.map((item, index) => {
        if (index >= 3) return;
        studentShareList.push(item);
      });
      content.commit("updateState", { studentShareList });
      return Promise.resolve(msg);
    },

     //获取学子分享列表(全部)
     async getLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const studentShareLists = [];
      results.map((item, index) => {
        if (index >= 4) return;
        studentShareLists.push(item);
      });
      content.commit("updateState", { studentShareLists });
      return Promise.resolve();
    },

    // 获取用户预约列表
    async getUserSubLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getUserSubLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { userSubLiveList: results });
      return Promise.resolve(msg);
    },
    // 获取用户直播回放
    async getUserLiveBack(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getUserLiveBack(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { userLiveBackList: results });
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
