import * as api from "../../service/index";
export default {
    namespaced: true,
    state: {
        isLoadingShow: false,
        detailData: {},
        videoId: "", // 视频ID
        currentId: "", // 系列视频当前ID
        videoUrl: "", // 视频地址
        reviewList: [], // 评论列表
        reviewCount: 0,
        relevantCourseList: [], // 相关课程列表
        curId: "", //当前课程id
    },
    mutations: {
        updateState(state, payload) {
            Object.keys(payload).map((key) => {
                state[key] = payload[key];
            });
        },
    },
    actions: {
        // 获取课程详情
        async getCourseDetail(content, payload) {
            const { detailData } = content.state;
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getCourseDetail(payload);
            content.commit("updateState", { isLoadingShow: false });
            content.commit("updateState", {
                detailData: {},
                videoUrl: "",
                videoId: "",
            });
            if (!succ) {
                return Promise.reject(msg);
            }
            content.commit("updateState", {
                detailData: {...detailData, ...results },
            });
            return Promise.resolve();
        },

        // 获取视频播放地址
        async getVideoUrl(content, payload) {
            const { detailData } = content.state;
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getVideoUrl(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                videoUrl: results.videoUrl,
                videoId: results.id,
                detailData: {
                    ...detailData,
                    detail: {
                        ...detailData.detail,
                        coverPhoto: results.coverPhoto,
                        name: results.name,
                    },
                },
            });
            return Promise.resolve();
        },

        // 获取课程评论
        async getCourseReview(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getCourseReview(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                reviewList: results,
                reviewCount: count,
            });
            return Promise.resolve();
        },

        // 获取相关课程
        async getRelevantCourseList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getRelevantCourseList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { relevantCourseList: results });
            return Promise.resolve();
        },

        // 添加评论
        async addUserReviews(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, msg } = await api.addUserReviews(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        //添加或取消用户收藏
        async addOrDelUserCollect(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, msg } = await api.addOrDelUserCollect(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 添加用户课程学习记录
        async addUserLearnRecord(content, payload) {
            // content.commit("updateState", { isLoadingShow: true });
            const { succ, msg } = await api.addUserLearnRecord(payload);
            // content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 创建订单
        async createOrder(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, msg } = await api.createOrder(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        //全部页面埋点
        async recordAllPage(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.recordAllPage(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { recordAllPage: '' });
            return Promise.resolve();
        },

        //用户行为埋点
        async recordBehavior(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.recordBehavior(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { recordBehavior: '' });
            return Promise.resolve();
        },

        //埋点接口(只在详情页记录)
        async recordDetailPage(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.recordDetailPage(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { recordBehavior: '' });
            return Promise.resolve();
        },
    },
};