import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import ElementUI from "element-ui";
import less from "less";
import store from "./store/index";
import moment from "moment";
import lazy from "./assets/images/lazy.jpg";
import VueLazyLoad from "vue-lazyload"; // 懒加载
import loading from "./assets/images/loading.gif";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from 'vue-cookies'
import getGraphicVerification from "./static/js/GraphicVerification.js";
VueCookies.config("7d");
Vue.use(VueCookies)
Vue.use(VueLazyLoad, {
  error: lazy, // 加载错误的图片
  loading: loading, // 加载时的图片
});
Vue.use(less);
Vue.use(ElementUI);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.prototype.imgUrl = 'https://lximg.eiceducation.com.cn/img/'; //图片地址
Vue.prototype.getGraphicVerification = getGraphicVerification; //图片地址

const vue = new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");

export default vue;
