import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    swiperList: [], // 轮播图列表
    liveClassList: [], // 直播列表
    courseList: [], // 热门课程列表
    guessLikeList: [], // 猜你喜欢
    schoolList: [], // 学校列表
    teacherList: [], // 名师列表
    studentShareList: [], // 学子分享
    studentShareLists: [], // 学子分享（全部）
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取板块内容
    async getPageInfoList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getPageInfoList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { swiperList: results.pageInfo });
      return Promise.resolve();
    },

    // 获取精选直播课
    async getRecentLive (content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getRecentLive(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { liveClassList:results });
      return Promise.resolve();
    },

    // 获取热门课程列表
    async getCourseList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const arr = [];
      results.map((item, index) => arr.push({ ...item, index: index + 1 }));
      content.commit("updateState", { courseList: arr });
      return Promise.resolve();
    },

    // 获取名师在线列表
    async getTeacherOnlineLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getTeacherOnlineLiveList(
        payload
      );
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { teacherList: results });
      return Promise.resolve();
    },

    //获取热门院校列表
    async getSchoolList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getSchoolList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const schoolList = [];
      results.map((item, index) => {
        if (index >= 6) return;
        schoolList.push(item);
      });
      content.commit("updateState", { schoolList });
      return Promise.resolve();
    },

    // 猜你喜欢列表
    async getGuessLike(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { guessLikeList: results });
      return Promise.resolve();
    },

    //获取学子分享列表
    async getStudentShareLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getStudentShareLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const studentShareList = [];
      results.map((item, index) => {
        if (index >= 3) return;
        studentShareList.push(item);
      });
      content.commit("updateState", { studentShareList });
      return Promise.resolve();
    },

    //获取学子分享列表(全部)
    async getLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const studentShareLists = [];
      results.map((item, index) => {
        if (index >= 4) return;
        studentShareLists.push(item);
      });
      content.commit("updateState", { studentShareLists });
      return Promise.resolve();
    },

    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },

    //用户行为埋点
    async recordBehavior(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordBehavior(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordBehavior: '' });
      return Promise.resolve();
    },
  },
};
