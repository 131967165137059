import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const Home = () => import("../pages/Home");
const My = () => import("../pages/My");
const TeachList = () => import("../pages/TeachList");
const UniversityList = () => import("../pages/UniversityList");
const Class = () => import("../pages/Class");
const Live = () => import("../pages/Live");
const ClassDetail = () => import("../pages/ClassDetail");
const TeacherDetail = () => import("../pages/TeacherDetail");
const LiveDetail = () => import("../pages/LiveDetail");
const UniversityDetail = () => import("../pages/UniversityDetail");
const CountryDetail = () => import("../pages/CountryDetail");
const SearchResult = () => import("../pages/SearchResult/index.vue");
const Living = () => import("../pages/Living");
const OrderInfo = () => import("../pages/OrderInfo");
const CourseList = () => import("../pages/CourseList");
const LiveList = () => import("../pages/LiveList");
const Error404 = () => import("../pages/Others/error");
const Router = new VueRouter({
	mode: "history",
	routes: [{
			path: "/home",
			component: Home,
			name: "home",
			meta: {
				index: 1,
				name: "首页",
			},
		},
		{
			path: "/class",
			component: Class,
			name: "class",
			meta: {
				index: 1,
				name: "课堂",
			},
		},
		{
			path: "/live",
			component: Live,
			name: "live",
			meta: {
				index: 1,
				name: "直播",
			},
		},
		{
			path: "/order/info",
			component: OrderInfo,
			name: "orderInfo",
			meta: {
				index: 1,
				name: "支付详情",
			},
		},
		{
			path: "/my",
			component: My,
			meta: {
				index: 1,
				name: "我的",
			},
			name: "my",
			children: [{
					path: "learnrecord",
					component: () => import("@pages/My/learnRecord"),
				},
				{
					path: "appointment",
					component: () => import("@pages/My/myAppointment"),
				},
				{
					path: "info",
					component: () => import("@pages/My/personInfo"),
				},
				{
					path: "order",
					component: () => import("@pages/My/myOrder"),
					name: "myOrder",
					meta: {
						index: 2,
						name: "我的订单",
					},
				},
				{
					path: "message",
					component: () => import("@pages/My/myMessage"),
				},
				{
					path: "shop",
					component: () => import("@pages/My/integralShop"),
				},
				{
					path: "integral",
					component: () => import("@pages/My/myIntegral"),
				},
				{
					path: "collect",
					component: () => import("@pages/My/myCollect"),
				},
				{
					path: "learnTime",
					component: () => import("@pages/My/learnTime"),
				},
				{
					path: "discounts",
					component: () => import("@pages/My/discounts"),
				},
				{
					path: "prize",
					component: () => import("@pages/My/myPrize"),
				},
				{
					path: "overlord",
					component: () => import("@pages/My/overlordClass"),
				},
			],
		},
		{
			path: "/search/result",
			component: SearchResult,
			name: "searchResult",
			meta: {
				index: 1,
				name: "搜索结果",
			},
		},
		{
			path: "/teacher/list",
			component: TeachList,
			name: "teacherList",
			meta: {
				index: 2,
				name: "名师列表",
			},
		},
		{
			path: "/teacher/detail",
			component: TeacherDetail,
			name: "teacherDetail",
			meta: {
				index: 3,
				name: "名师详情",
			},
		},
		{
			path: "/university/list",
			component: UniversityList,
			name: "universityList",
			meta: {
				index: 2,
				name: "院校列表",
			},
		},
		{
			path: "/university/detail",
			component: UniversityDetail,
			name: "universityDetail",
			meta: {
				index: 3,
				name: "院校详情",
			},
		},

		{
			path: "/live/detail",
			component: LiveDetail,
			name: "liveDetail",
			meta: {
				index: 3,
				name: "直播详情",
			},
		},
		{
			path: "/live/list",
			component: LiveList,
			name: "liveList",
			meta: {
				index: 2,
				name: "直播列表",
			},
		},
		{
			path: "/course/detail",
			component: ClassDetail,
			name: "courseDetail",
			meta: {
				index: 3,
				name: "课程详情",
			},
		},
		{
			path: "/course/list",
			component: CourseList,
			name: "courseList",
			meta: {
				index: 2,
				name: "课程列表",
			},
		},

		{
			path: "/country/detail",
			component: CountryDetail,
			name: "countryDetail",
			meta: {
				index: 3,
				name: "国家详情",
			},
		},
		{
			path: "/Living",
			component: Living,
		},
		{
			path: "/Other/error",
			component: Error404,
			name: "error",
		},
		{
			path: "/",
			redirect: "/home",
		},
		{
			path: "*",
			redirect: "/Other/error",
		}
	],
});

Router.beforeEach((to, from, next) => {


	next((vm) => {
		// 因为当钩子执行前，组件实例还没被创建
		// vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
		console.log(vm); // 当前组件的实例
	});
})

export default Router;
