import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    swiperList: [], // 轮播图列表
    hotCourseList: [], // 课程列表
    studyAbroadList: [], // 留学课程列表
    universityList: [], // 大学排名解析
    schoolList: [], // 热门院校列表
    hotStageCourseList: [], // 热门阶段课程列表
    capacityUpList: [], // 能力提升列表
    vipList: [], // VIP专区列表
    abroadExamList: [], // 出国考试列表
    lookCourseList: [], // 看课指南列表
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取板块内容
    async getPageInfoList(content, payload) {
      const { results, succ } = await api.getPageInfoList({ id: 127 });
      if (!succ) return;
      const swiperList = [];
      const universityList = [];
      const schoolList = [];
      results.pageInfo.map(
        (item) => item.pageInfoTypeId === 129 && swiperList.push(item)
      );
      results.pageInfo.map(
        (item) =>
          item.pageInfoTypeId === 137 &&
          universityList.push({
            coverPhoto: item.imageUrl,
            name: item.title,
            tags: item.tags,
            jumpUrl: item.jumpUrl,
          })
      );
      results.pageInfo.map(
        (item) =>
          item.pageInfoTypeId === 136 &&
          schoolList.push({
            coverPhoto: item.imageUrl,
            name: item.title,
            tags: item.tags,
            jumpUrl: item.jumpUrl,
          })
      );
      content.commit("updateState", {
        swiperList,
        universityList,
        schoolList,
      });
    },

    // 获取热门课程列表
    async getCourseList(content, payload) {
      const { results, succ } = await api.getCourseList(payload);
      if (!succ) return;
      const arr = [];
      results.map((item, index) => arr.push({ ...item, index: index + 1 }));
      content.commit("updateState", { hotCourseList: arr });
    },
    // 获取留学专业列表(课程列表接口)
    async getStudyAbroadList(content, payload) {
      const { results, succ } = await api.getCourseList(payload);
      if (!succ) return;
      content.commit("updateState", {
        studyAbroadList: results,
      });
    },
    //获取热门阶段课程列表
    async getHotStageCourseList(content, payload) {
      const { results, succ } = await api.getCourseList(payload);
      if (!succ) return;
      content.commit("updateState", {
        hotStageCourseList: results,
      });
    },
    // 获取能力提升列表
    async getCapacityUpList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, msg, succ } = await api.getCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { capacityUpList: results });
      return Promise.resolve(msg);
    },

    // 获取看课指南列表
    async getLookCourseList(content, payload) {
      const { results, succ } = await api.getPageInfoList(payload);
      if (!succ) return;
      const lookCourseList = [];
      results.pageInfoType.map((item1) => {
        const obj = { title: item1.name, list: [] };
        results.pageInfo.map((item) => {
          if (item1.id === item.pageInfoTypeId) {
            obj.list.push({
              coverPhoto: item.imageUrl,
              name: item.title,
              tags: item.tags,
              jumpUrl: item.jumpUrl,
            });
          }
        });
        lookCourseList.push(obj);
      });
      content.commit("updateState", {
        lookCourseList,
      });
    },

    // 获取出国考试列表
    async getAbroadExamList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, msg, succ } = await api.getCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { abroadExamList: results });
      return Promise.resolve(msg);
    },
    // 获取VIP专区列表
    async getVipList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, msg, succ } = await api.getCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { vipList: results });
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
