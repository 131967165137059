import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    countryList: [], // 国家列表
    liveTypeList: [], // 直播内容分类列表
    liveStateTypeList: [
      { value: -1, name: "全部" },
      { value: 1, name: "未开始" },
      { value: 2, name: "进行中" },
      { value: 3, name: "已结束" },
      { value: 4, name: "有回放" },
      { value: 5, name: "今日直播" },
    ], // 直播状态分类列表
    spearkerTypeList: [
      { value: -1, name: "全部" },
      { value: 1, name: "名师" },
      { value: 2, name: "学子" },
      { value: 3, name: "嘉宾" },
    ], // 主讲人分类列表

    professionalTypeList: [], // 专业分类

    gradeTypeList: [], // 留学阶段分类

    searchData: {
      keyword: "", // 搜索关键字
      categoryId: -1, // 分类ID
      speakerType: -1,
      gradeId: -1,
      status: -1,
      countryId: -1, // 国家ID
      professionId: -1, // 专业ID
    },
    count: 0,
    nowPage: 1,
    pageSize: 10,
    listData: [],
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取国家列表
    async getCountryList(content, payload) {
      const { succ, results } = await api.getCountryList();
      if (!succ) return;
      const arr = [{ id: -1, name: "全部" }];
      results.map((item) => arr.push({ ...item }));
      content.commit("updateState", {
        countryList: arr,
      });
    },

    // 获取枚举列表
    async getEnumList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getEnumList();
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);

      const liveTypeList = [{ value: -1, name: "全部" }];
      const professionalTypeList = [{ value: -1, name: "全部" }];
      const gradeTypeList = [{ value: -1, name: "全部" }];
      results
        .filter((item) => item.id === 63)[0]
        .childs.map((item) => liveTypeList.push(item));
      results
        .filter((item) => item.id === 34)[0]
        .childs.map((item) => professionalTypeList.push(item));
      results
        .filter((item) => item.id === 23)[0]
        .childs.map((item) => gradeTypeList.push(item));

      content.commit("updateState", {
        liveTypeList,
        professionalTypeList,
        gradeTypeList,
      });
      return Promise.resolve(msg);
    },

    // 获取直播列表
    async getLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const params = {};
      Object.keys(payload).map((key) => {
        if (payload[key] === "" || payload[key] === -1) {
          return;
        }
        params[key] = payload[key];
      });
      const { results, succ, msg, count, page } = await api.getLiveList(params);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", {
        listData: results,
        count,
        nowPage: Number(page),
      });
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
