import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    detailData: {}, // 详情数据
    relevancyCourseList: [], // 院校相关课程列表
    relevanSchoolList: [], // 相关名校
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取院校详情
    async getSchoolDetail(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg, results } = await api.getSchoolDetail(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { detailData: results });
      return Promise.resolve(msg);
    },

    // 获取院校关联的课程列表
    async getSchoolRelevancyCourseList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg, results } = await api.getSchoolRelevancyCourseList(
        payload
      );
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { relevancyCourseList: results });
      return Promise.resolve(msg);
    },

    // 获取相关名校
    async getRelevantSchoolList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg, results } = await api.getRelevantSchoolList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { relevanSchoolList: results });
      return Promise.resolve(msg);
    },

    // 收藏or取消收藏
    async addOrDelUserCollect(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg } = await api.addOrDelUserCollect(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
