import axios from "axios";
import vue from "../main";
axios.interceptors.request.use(
  (config) => {
    // 这里的config包含每次请求的内容
    const token = window.$cookies.get("token");
    if (token) {
      config.headers["X-EICCHANNEL-TOKEN"] = token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res.data.code === 403 && !window.$cookies.get("token")) {
      vue.$store.commit("MyModel/updateState", { userInfo: null });
      return {
        msg: "当前token已过期，请重新登录！",
        succ: false,
      };
    }
    if (!res.status) {
      return Promise.reject(res);
    }
    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
