<script>
    import Login from "./Login";
    export default{
        name:"LeftTabbox",
        data() {
            return {
                nowPage: "",
            };
        },
        async mounted() {
            
        },
        methods: {
            toggleNav(url) {
                this.$router.push({ path: url });
                this.nowPage = url;
            },
        },
        watch: {
            $route(to, from) {
                this.nowPage = to.matched[0].path;
            },
        },
        render() {
            return (
                <div class="lefttabbox">
                    <i class="leftlogo"/>
                    <div class="content">
                        <ul class="tabname">
                            <li onclick={() => this.toggleNav("/home")}
                                class={`tabnameli ${this.nowPage === "/home" ? "active" : null}`}>首页 
                                <div class="border"></div>
                            </li>
                            <li onclick={() => this.toggleNav("/live")}
                                class={`tabnameli ${this.nowPage === "/live" ? "active" : null}`}>直播
                                <div class="border"></div>
                            </li>
                            <li onclick={() => this.toggleNav("/class")}
                                class={`tabnameli ${this.nowPage === "/class" ? "active" : null}`}>课堂
                                <div class="border"></div>
                            </li>
                            <li onclick={() => {
                                    if (window.$cookies.get("token")) {
                                        this.toggleNav("/my/learnRecord");
                                    } else {
                                        this.$store.commit("LoginModel/updateState", {
                                        isShowLogin: true,
                                        loginState: "login",
                                        });
                                    }
                                }}
                                class={`tabnameli ${this.nowPage === "/my" ? "active" : null}`}
                                >我的
                            </li>
                        </ul>
                    </div>
                </div>
            );
        },
    };
</script>
<style lang="less" scoped>
    .lefttabbox {
        position: fixed;
        display: flex;
        flex-direction: column;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        left: 2%;
        .content{
            width: 80px;
            height: 188px;
            background: #FFFFFF;
            border: 1px solid #DCE0E6;
            border-radius: 8px;
            .tabname{
                height:100%;
                border-radius: 8px;
                .tabnameli{
                    list-style-type:none;
                    height: 47px;
                    text-align: center;
                    line-height: 47px;
                    width: 80px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    position: relative;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #63686F;
                    cursor: pointer;
                    .border{
                        width: 50px;
                        border-bottom: 1px solid #DCE0E6;
                        position: absolute;
                        bottom: 0px;
                        left: 15px;
                    }
                }
                .active{
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #006EEB;
                }
            }
        }
        .leftlogo{
            width: 46px;
            height: 41px;
            background-image: url("../assets/images/leftlogo.png");
            background-size: 100%;
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            z-index: 99;
            left: 18px;
            top: -35px;
        }
    }
</style>