<script>
export default {
  name: "Loading",
  props:{
      isShow:Boolean
  },
  render() {
    return (
      <div class="loadingBox" v-show={false}>
        <i class="icon-loading" />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.loadingBox {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  .icon-loading {
    width: 100px;
    height: 100px;
    display: block;
    background-image: url("../assets/images/loading.gif");
    background-size: 100%;
    background-repeat: no-repeat;
  }
}
</style>