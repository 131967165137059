import { request, upload } from "../utils";
let url = window.location.hostname;
console.log(url)
const BaseUrl =
    process.env.NODE_ENV === "development" ?
    `http://localhost:8080` :
    (url.indexOf("www0") >= 0 ? "https://wxapi0-kt.eiceducation.com.cn" :"https://wxapi-kt.eiceducation.com.cn");

const URL = {
    // 获取直播列表
    GET_LIVE_LIST: `${BaseUrl}/WxApp/GetLiveList`,

    // 获取课程列表
    GET_COURSE_LIST: `${BaseUrl}/WxApp/GetCourseList`,

    // 获取学校列表
    GET_SCHOOL_LIST: `${BaseUrl}/WxApp/GetSchoolList`,
    // 获取名师列表
    GET_TEACHER_LIST: `${BaseUrl}/WxApp/GetTeacherList`,
    // 获取名师在线直播列表
    GET_TEACHER_ONLINE_LIST: `${BaseUrl}/WxApp/GetTeacherOnlineLiveList`,

    // 获取学子分享直播列表
    GET_SHARE_LIVE_LIST: `${BaseUrl}/WxApp/GetStudentShareLiveList`,

    // 获取国家列表
    GET_COUNTRY_LIST: `${BaseUrl}/WxApp/GetCountryList`,

    // 获取枚举列表
    GET_ENUM_LIST: `${BaseUrl}/WxApp/GetEnumList`,

    // 获取板块内容列表
    GET_PAGEINFO_LIST: `${BaseUrl}/WxApp/GetPageInfoList`,

    // 获取直播预告列表
    GET_LIVE_FORESHOW: `${BaseUrl}/WxApp/GetLiveForeshow`,

    // 根据IP获取直播列表
    GET_LIVE_BYIP: `${BaseUrl}/WxApp/GetLiveListByIp`,

    // 获取直播回放列表
    GET_LIVEBACK_LIST: `${BaseUrl}/WxApp/GetLiveBackList`,

    // 获取课程详情
    GET_COURSE_DETAIL: `${BaseUrl}/WxApp/GetCourseDetail`,

    // 获取视频播放地址
    GET_VIDEO_URL: `${BaseUrl}/WxApp/GetVideoUrl`,

    // 获取课程评论
    GET_COURSE_REVIEQLIST: `${BaseUrl}/WxApp/GetCourseReviewList`,

    // 添加用户评论
    ADD_USER_REVIEWS: `${BaseUrl}/WxApp/AddUserReviews`,

    // 获取相关课程
    GET_RELEVANT_COURSELIST: `${BaseUrl}/WxApp/GetRelevantCourseList`,

    // 获取用户预约直播列表
    GET_USER_SUBLIVE: `${BaseUrl}/WxApp/GetUserSubLiveList`,

    // 发送验证码
    SEND_CODE: `${BaseUrl}/WxApp/SendCode`,
    // 发送验证码
    SEND_CODE_NEW: `${BaseUrl}/WxApp/SendGraphCode`,

    // 获取国家区号列表
    GET_COUNTRYCODE_LIST: `${BaseUrl}/WxApp/GetCountryCodeList`,

    // 注册
    USER_REGISTER: `${BaseUrl}/WxApp/UserRegister`,

    // 登录
    USER_LOGIN: `${BaseUrl}/WxApp/UserLogin`,

    // 获取用户直播回放
    GET_USER_LIVEBACK: `${BaseUrl}/WxApp/GetUserLiveBack`,

    // 获取国家详情
    GET_COUNTRY_DETAIL: `${BaseUrl}/WxApp/GetCountryDetail`,

    // 添加或取消用户收藏
    USER_COLLECT: `${BaseUrl}/WxApp/AddOrDelUserCollect`,

    // 获取直播详情
    GET_LIVE_DETAIL: `${BaseUrl}/WxApp/GetLiveDetail`,

    // 获取相关直播
    GET_RELEVANT_LIVE: `${BaseUrl}/WxApp/GetRelevantLiveList`,

    // 获取院校详情
    GET_SCHOOL_DETAIL: `${BaseUrl}/WxApp/GetSchoolDetail`,

    // 获取院校关联的课程列表
    GET_SCHOOL_RELEVANCY_COURSELIST: `${BaseUrl}/WxApp/GetSchoolRelevancyCourseList`,

    // 获取相关名校
    GET_SCHOOL_RELEVANCY_LIST: `${BaseUrl}/WxApp/GetRelevantSchoolList`,

    // 获取直播播放地址
    GET_LIVE_URL: `${BaseUrl}/WxApp/GetLiveUrl`,

    // 添加或取消预约直播
    LIVE_SUBSCRIBE: `${BaseUrl}/WxApp/AddOrDelLiveSubscribe`,

    // 获取用户消息列表
    GET_NOTICE_LIST: `${BaseUrl}/WxApp/GetNoticeList`,

    // 获取我的收藏列表
    GET_MYCOLLECT_LIST: `${BaseUrl}/WxApp/GetMyCollectList`,

    // 获取名师详情
    GET_TEACHER_DETAIL: `${BaseUrl}/WxApp/GetTeacherDetail`,

    // 获取名师关联的课程列表
    GET_TEACHER_RELEVANCY_COURSELIST: `${BaseUrl}/WxApp/GetTeacherRelevancyCourseList`,

    // 获取名师关联的直播列表
    GET_TEACHER_RELEVANCY_LIVELIST: `${BaseUrl}/WxApp/GetTeacherRelevancyLiveList`,

    // 获取相关名师
    GET_RELEVANT_TEACHERLIST: `${BaseUrl}/WxApp/GetRelevantTeacherList`,

    // 获取最新消息数量
    GET_NEWNOTICE_NUM: `${BaseUrl}/WxApp/GetNewNoticeNum`,

    // 获取用户学习时长
    GET_USER_LEARNTIME: `${BaseUrl}/WxApp/GetUserLearnTime`,

    // 判断用户是否签到
    IS_SIGN: `${BaseUrl}/WxApp/IsSign`,

    // 用户签到
    USER_SIGN: `${BaseUrl}/WxApp/UserSign`,

    // 获取我的奖品列表
    GET_MYPRIZE_LIST: `${BaseUrl}/WxApp/GetMyPrizeList`,

    // 获取热门关键词列表
    GET_HOTKEY_LIST: `${BaseUrl}/HotKey/GetHotKeyList`,

    // 获取书籍列表
    GET_BOOK_LIST: `${BaseUrl}/Book/GetBookList`,

    // 获取书籍浏览授权
    GET_BOOK_DETAIL: `${BaseUrl}/Book/GetBookDetail`,

    // 获取弹出层二维码Base64二维码
    GET_URL_QRCODE: `${BaseUrl}/WxApp/GetUrlQRCode`,

    // 获取学习记录
    GET_USER_LEARNRECORD: `${BaseUrl}/WxApp/GetUserLearnRecord`,

    // 添加用户课程学习记录
    ADD_USER_LEARNRECORD: `${BaseUrl}/WxApp/AddUserLearnRecord`,

    // 获取用户订单列表
    GET_USER_ORDERLIST: `${BaseUrl}/WxApp/GetUserOrderList`,

    // 获取订单详情
    GET_ORDER_DETAIL: `${BaseUrl}/WxApp/GetOrderDetail`,

    // 获取用户信息
    GET_USER_INFO: `${BaseUrl}/WxApp/GetUserInfo`,

    // 获取积分任务列表
    GET_TASK_LIST: `${BaseUrl}/WxApp/GetTaskList`,

    // 上传用户头像
    UP_USER_HEAD: `${BaseUrl}/WxApp/UpdUserHead`,

    // 创建订单
    CREATE_ORDER: `${BaseUrl}/WxApp/CreateOrder`,

    // 获取商品列表
    GET_GOODS_LIST: `${BaseUrl}/WxApp/GetGoodsList`,

    // 获取积分商品详情
    GET_GOODS_DETAIL: `${BaseUrl}/WxApp/GetGoodsDetail`,

    // 领取积分
    GET_TASK_SCORE: `${BaseUrl}/WxApp/GetTaskScore`,

    // 获取积分记录
    GET_SCORE_RECORDLIST: `${BaseUrl}/WxApp/GetScoreRecordList`,

    // 获取优惠券列表
    GET_COUPON_LIST: `${BaseUrl}/WxApp/GetCouponList`,

    // 兑换优惠券
    EXCHANGE_COUPON: `${BaseUrl}/WxApp/ExchangeCoupon`,

    // 获取用户地址列表
    GET_USER_ADDRESSLIST: `${BaseUrl}/WxApp/GetUserAddressList`,

    // 添加或修改用户地址
    ADDORUP_USER_ADDRESS: `${BaseUrl}/WxApp/AddOrUpdUserAddress`,

    // 删除用户地址
    DEL_USER_ADDRESS: `${BaseUrl}/WxApp/DelUserAddress`,

    // 全局搜索
    SITE_SEARCH: `${BaseUrl}/WxApp/SiteSearch`,
    SITE_SEARCH2: `https://search.eiceducation.com.cn/api/Search/QueryList`,

    // 获取购买详情
    BUY_DETAIL: `${BaseUrl}/WxApp/BuyDetail`,

    // 获取有效优惠券
    GET_EFFECTIVE_COUPON: `${BaseUrl}/WxApp/GetEffectiveCoupon`,

    // 获取支付二维码
    GET_WXPAY_QRCODE: `${BaseUrl}/WxApp/GetWxPayQRCode`,

    // 取消用户订单
    CANCLE_ORDER: `${BaseUrl}/WxApp/CancelOrder`,

    // 修改用户手机号
    UP_USER_MOBILE: `${BaseUrl}/WxApp/UpdUserMobile`,

    // 完善用户信息
    COMPLETE_USER_INFO: `${BaseUrl}/WxApp/CompleteUserInfo`,

    // 修改个人信息
    UP_USER_INFO: `${BaseUrl}/WxApp/UpdUserInfo`,

    // 订单支付检查
    ORDER_PAY_CHECK: `${BaseUrl}/WxApp/OrderPayCheck`,

    // 创建积分商城订单
    CREATE_SHOP_ORDER: `${BaseUrl}/WxApp/CreateShopOrders`,

    // 获取商城订单列表
    GET_SHOP_ORDER_LIST: `${BaseUrl}/WxApp/GetShopOrdersList`,

    // 获取商城订单详情
    GET_SHOP_ORDER_DETAIL: `${BaseUrl}/WxApp/GetShopOrdersDetail`,

    // 获取相关商品
    GET_RELEVANT_GOOD_LIST: `${BaseUrl}/WxApp/GetRelevantGoodsList`,

    // 获取物流信息
    GET_ORDER_EXPRESS: `${BaseUrl}/WxApp/GetOrdersExpress`,

    // 忘记密码
    USER_FORGET: `${BaseUrl}/WxApp/UserForget`,

    // 检查验证码
    CHECK_CODE: `${BaseUrl}/WxApp/CheckCode`,

    // 获取微信授权参数
    GET_AUTHORIZE_PARAM: `${BaseUrl}/WxOAuth_PC/GetAuthorizeParam`,

    // 获取AccessToken
    GET_ACCESS_TOKEN: `${BaseUrl}/WxOAuth_PC/GetAccessToken`,

    // 获取微信用户授权信息
    GET_OAUTHUSER_INFO: `${BaseUrl}/WxOAuth_PC/GetOAuthUserInfo`,

    // 获取所在区域省市区列表信息
    GET_AREA_LIST: `${BaseUrl}/WxApp/GetAreaList`,

    // 获取近期直播
    GET_RECENT_LIVE: `${BaseUrl}/WxApp/GetRecentLive`,

    //全部页面埋点
    Data_Set2_Get: `https://cmsapi.eiceducation.com.cn/api/BiData/DataSet2Get`,

    //用户行为记录埋点(开窗 = 1,保存客服微信 = 2,点击400 = 3,登录 = 4)
    Data_Set3_Get: `https://cmsapi.eiceducation.com.cn/api/BiData/DataSet3Get`,

    //埋点接口(只在详情页记录)
    Data_Set1_Get: `https://cmsapi.eiceducation.com.cn/api/BiData/DataSet1Get`,
    
};

// 获取微信用户授权信息
export const getOAUthUserInfo = (params) =>
    request(URL.GET_OAUTHUSER_INFO, { method: "get", params });

// 获取AccessToken
export const getAccessToken = (params) =>
    request(URL.GET_ACCESS_TOKEN, { method: "get", params });

// 获取微信授权参数
export const getAuthorizeParam = (params) =>
    request(URL.GET_AUTHORIZE_PARAM, { method: "get", params });

// 检查验证码
export const checkCode = (data) =>
    request(URL.CHECK_CODE, { method: "post", data });

// 忘记密码
export const userForget = (data) =>
    request(URL.USER_FORGET, { method: "post", data });

// 获取物流信息
export const getOrderExpress = (params) =>
    request(URL.GET_ORDER_EXPRESS, { method: "get", params });

// 获取相关商品
export const getRelevantGoodList = (params) =>
    request(URL.GET_RELEVANT_GOOD_LIST, { method: "get", params });

// 获取商城订单详情
export const getShopOrderDetail = (params) =>
    request(URL.GET_SHOP_ORDER_DETAIL, { method: "get", params });

// 获取商城订单列表
export const getShopOrderList = (params) =>
    request(URL.GET_SHOP_ORDER_LIST, { method: "get", params });

// 创建积分商城订单
export const createShopOrder = (data) =>
    request(URL.CREATE_SHOP_ORDER, { method: "post", data });

// 订单支付检查
export const orderPayCheck = (params) =>
    request(URL.ORDER_PAY_CHECK, { method: "get", params });

// 修改个人信息
export const upUserInfo = (data) =>
    request(URL.UP_USER_INFO, { method: "post", data });

// 完善用户信息
export const completeUserInfo = (data) =>
    request(URL.COMPLETE_USER_INFO, { method: "post", data });

// 修改用户手机号
export const upUserMobile = (data) =>
    request(URL.UP_USER_MOBILE, { method: "post", data });

// 取消用户订单
export const cancleOrder = (params) =>
    request(URL.CANCLE_ORDER, { method: "get", params });

// 获取支付二维码
export const getwxPayQRCode = (params) =>
    request(URL.GET_WXPAY_QRCODE, { method: "get", params });

// 获取有效优惠券
export const getEffectiveCoupon = (params) =>
    request(URL.GET_EFFECTIVE_COUPON, { method: "get", params });

// 获取购买详情
export const buyDetail = (data) =>
    request(URL.BUY_DETAIL, { method: "post", data });

// 全局搜索
export const siteSearch = (params) =>
    request(URL.SITE_SEARCH, { method: "get", params });
		
export const siteSearch2 = (data) =>
		request(URL.SITE_SEARCH2, { method: "post", data });
		

// 删除用户地址
export const delUserAddress = (params) =>
    request(URL.DEL_USER_ADDRESS, { method: "get", params });

// 添加或修改用户地址
export const addOrUpUserAddress = (data) =>
    request(URL.ADDORUP_USER_ADDRESS, { method: "post", data });

// 获取用户地址列表
export const getUserAddressList = (params) =>
    request(URL.GET_USER_ADDRESSLIST, { method: "get", params });

// 兑换优惠券
export const exchangeCoupon = (data) =>
    request(URL.EXCHANGE_COUPON, { method: "post", data });

// 获取优惠券列表
export const getCouponList = (params) =>
    request(URL.GET_COUPON_LIST, { method: "get", params });

// 获取积分记录
export const getScoreRecordList = (params) =>
    request(URL.GET_SCORE_RECORDLIST, { method: "get", params });

// 领取积分
export const getTaskScore = (params) =>
    request(URL.GET_TASK_SCORE, { method: "get", params });

// 获取积分商品详情
export const getGoodsDetail = (params) =>
    request(URL.GET_GOODS_DETAIL, { method: "get", params });

//获取商品列表
export const getGoodsList = (params) =>
    request(URL.GET_GOODS_LIST, { method: "get", params });

// 创建订单
export const createOrder = (data) =>
    request(URL.CREATE_ORDER, { method: "post", data });

// 上传用户头像
export const upUserHead = (data) =>
    upload(URL.UP_USER_HEAD, { method: "post", data });

// 获取积分任务列表
export const getTaskList = (params) =>
    request(URL.GET_TASK_LIST, { method: "get", params });

// 获取用户信息
export const getUserInfo = (params) =>
    request(URL.GET_USER_INFO, { method: "get", params });

// 获取订单详情
export const getOrderDetail = (params) =>
    request(URL.GET_ORDER_DETAIL, { method: "get", params });

// 获取用户订单列表
export const getUserOrderList = (params) =>
    request(URL.GET_USER_ORDERLIST, { method: "get", params });

// 添加用户课程学习记录
export const addUserLearnRecord = (data) =>
    request(URL.ADD_USER_LEARNRECORD, { method: "post", data });

// 获取学习记录
export const getUserLearnRecord = (params) =>
    request(URL.GET_USER_LEARNRECORD, { method: "get", params });

// 获取书籍列表
export const getBookList = (params) =>
    request(URL.GET_BOOK_LIST, { method: "get", params });

// 获取书籍浏览授权
export const getBookDetail = (params) =>
    request(URL.GET_BOOK_DETAIL, { method: "get", params });

// 获取弹出层二维码Base64代码
export const getUrlQRCode = (params) =>
    request(URL.GET_URL_QRCODE, { method: "get", params });

// 获取热门关键词列表
export const getHotKeyList = (params) =>
    request(URL.GET_HOTKEY_LIST, { method: "get", params });

// 获取我的奖品列表
export const getMyPrizeList = (params) =>
    request(URL.GET_MYPRIZE_LIST, { method: "get", params });

// 用户签到
export const userSign = (data) =>
    request(URL.USER_SIGN, { method: "post", data });

// 判断用户是否签到
export const isSign = (data) => request(URL.IS_SIGN, { method: "post", data });

// 获取用户学习时长
export const getUserLearnTime = (params) =>
    request(URL.GET_USER_LEARNTIME, { method: "get", params });

// 获取最新消息数量
export const getNewNoticeNum = (params) => {
    return request(URL.GET_NEWNOTICE_NUM, {
        method: "get",
        params,
    });
};

// 获取相关名师
export const getRelevantTeacherList = (params) => {
    return request(URL.GET_RELEVANT_TEACHERLIST, {
        method: "get",
        params,
    });
};

// 获取名师关联的直播列表
export const getTeacherRelevancyLiveList = (params) => {
    return request(URL.GET_TEACHER_RELEVANCY_LIVELIST, {
        method: "get",
        params,
    });
};

// 获取名师关联的课程列表
export const getTeacherRelevancyCourseList = (params) => {
    return request(URL.GET_TEACHER_RELEVANCY_COURSELIST, {
        method: "get",
        params,
    });
};

// 获取名师详情
export const getTeacherDetail = (params) => {
    return request(URL.GET_TEACHER_DETAIL, {
        method: "get",
        params,
    });
};

// 获取我的收藏列表
export const getMyCollectList = (params) => {
    return request(URL.GET_MYCOLLECT_LIST, {
        method: "get",
        params,
    });
};

// 获取用户消息列表
export const getNoticeList = (params) => {
    return request(URL.GET_NOTICE_LIST, {
        method: "get",
        params,
    });
};

// 添加或取消预约直播
export const addOrDelLiveSubscribe = (data) => {
    return request(URL.LIVE_SUBSCRIBE, {
        method: "post",
        data,
    });
};

// 获取直播播放地址
export const getLiveUrl = (params) => {
    return request(URL.GET_LIVE_URL, {
        method: "get",
        params,
    });
};

// 获取相关名校
export const getRelevantSchoolList = (params) => {
    return request(URL.GET_SCHOOL_RELEVANCY_LIST, {
        method: "get",
        params,
    });
};

// 获取院校关联的课程列表
export const getSchoolRelevancyCourseList = (params) => {
    return request(URL.GET_SCHOOL_RELEVANCY_COURSELIST, {
        method: "get",
        params,
    });
};

// 获取院校详情
export const getSchoolDetail = (params) => {
    return request(URL.GET_SCHOOL_DETAIL, {
        method: "get",
        params,
    });
};

// 获取相关直播
export const getRelevantLiveList = (params) => {
    return request(URL.GET_RELEVANT_LIVE, {
        method: "get",
        params,
    });
};

// 获取直播详情
export const getliveDetal = (params) => {
    return request(URL.GET_LIVE_DETAIL, {
        method: "get",
        params,
    });
};

// 添加或取消用户收藏
export const addOrDelUserCollect = (data) => {
    return request(URL.USER_COLLECT, {
        method: "post",
        data,
    });
};

// 获取国家详情
export const getCountryDetail = (params) => {
    return request(URL.GET_COUNTRY_DETAIL, {
        method: "get",
        params,
    });
};

// 获取用户直播回放
export const getUserLiveBack = (params) => {
    return request(URL.GET_USER_LIVEBACK, {
        method: "get",
        params,
    });
};

// 获取用户预约直播列表
export const getUserSubLiveList = (params) => {
    return request(URL.GET_USER_SUBLIVE, {
        method: "get",
        params,
    });
};

// 登录
export const userLogin = (data) => {
    return request(URL.USER_LOGIN, {
        method: "post",
        data,
    });
};

// 注册
export const userRegister = (data) => {
    return request(URL.USER_REGISTER, {
        method: "post",
        data,
    });
};

// 获取国家区号列表
export const getCountryCodeList = (params) => {
    return request(URL.GET_COUNTRYCODE_LIST, {
        method: "get",
        params,
    });
};

// 发送验证码
export const sendCode = (data) => {
    return request(URL.SEND_CODE_NEW, {
        method: "POST",
        data,
    });
};

// 获取直播列表
export const getLiveList = (params) => {
    return request(URL.GET_LIVE_LIST, {
        method: "GET",
        params,
    });
};

// 获取课程列表
export const getCourseList = (params) => {
    return request(URL.GET_COURSE_LIST, {
        method: "GET",
        params,
    });
};

//获取学校列表
export const getSchoolList = (params) => {
    return request(URL.GET_SCHOOL_LIST, {
        method: "GET",
        params,
    });
};

// 获取名师列表
export const getTeacherList = (params) => {
    return request(URL.GET_TEACHER_LIST, {
        method: "GET",
        params,
    });
};

// 获取名师在线直播列表
export const getTeacherOnlineLiveList = (params) => {
    return request(URL.GET_TEACHER_ONLINE_LIST, {
        method: "GET",
        params,
    });
};

// 获取学子分享直播列表
export const getStudentShareLiveList = (params) => {
    return request(URL.GET_SHARE_LIVE_LIST, {
        method: "GET",
        params,
    });
};

// 获取国家列表
export const getCountryList = (params) => {
    return request(URL.GET_COUNTRY_LIST, {
        method: "GET",
        params,
    });
};

// 获取枚举列表
export const getEnumList = (params) => {
    return request(URL.GET_ENUM_LIST, {
        method: "GET",
        params,
    });
};

// 获取板块内容列表
export const getPageInfoList = (params) => {
    return request(URL.GET_PAGEINFO_LIST, {
        method: "GET",
        params,
    });
};

// 获取直播预告
export const getLiveForeShow = (params) => {
    return request(URL.GET_LIVE_FORESHOW, {
        method: "GET",
        params,
    });
};

// 根据IP获取直播列表
export const getLiveListByIp = (params) => {
    return request(URL.GET_LIVE_BYIP, {
        method: "GET",
        params,
    });
};

// 获取直播回放列表
export const getLiveBackList = (params) => {
    return request(URL.GET_LIVEBACK_LIST, {
        method: "GET",
        params,
    });
};

// 获取课程详情
export const getCourseDetail = (params) => {
    return request(URL.GET_COURSE_DETAIL, {
        method: "GET",
        params,
    });
};

// 获取视频播放地址
export const getVideoUrl = (params) => {
    return request(URL.GET_VIDEO_URL, {
        method: "GET",
        params,
    });
};

// 获取课程评论
export const getCourseReview = (params) => {
    return request(URL.GET_COURSE_REVIEQLIST, {
        method: "GET",
        params,
    });
};

// 添加用户评论
export const addUserReviews = (data) => {
    return request(URL.ADD_USER_REVIEWS, {
        method: "POST",
        data,
    });
};

// 获取相关课程
export const getRelevantCourseList = (params) => {
    return request(URL.GET_RELEVANT_COURSELIST, {
        method: "GET",
        params,
    });
};

// 获取所在区域省市区列表信息
export const getAreaList = (params) => {
    return request(URL.GET_AREA_LIST, {
        method: "GET",
        params,
    });
};

// 获取近期直播
export const getRecentLive = (params) => {
    return request(URL.GET_RECENT_LIVE, {
        method: "GET",
        params,
    });
};

// 埋点接口(所有页面记录)
export const recordAllPage = (params) => {
    return request(URL.Data_Set2_Get, {
        method: "GET",
        params,
    });
};

// 埋点接口(用户行为记录)
export const recordBehavior = (params) => {
    return request(URL.Data_Set3_Get, {
        method: "GET",
        params,
    });
};
//埋点接口(只在详情页记录)
export const recordDetailPage = (params) => {
    return request(URL.Data_Set1_Get, {
        method: "GET",
        params,
    });
};