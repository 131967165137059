import Vue from "vue";
import Vuex from "vuex";
import HomeModel from "./modules/Home";
import MyModel from "./modules/My";
import TeachListModel from "./modules/TeachList";
import UniversityListModel from "./modules/UniversityList";
import ClassModel from "./modules/Class";
import LiveModel from "./modules/Live";
import ClassDetailModel from "./modules/ClassDetail";
import CountryDetailModel from "./modules/CountryDetail";
import LoginModel from "./modules/Login.js";
import LiveDetailModel from "./modules/LiveDetail.js";
import UniversityDetail from "./modules/UniversityDetail";
import TeacherDetail from "./modules/TeacherDetail";
import CourseList from "./modules/CourseList";
import LiveList from "./modules/LiveList";
import SearchResult from "./modules/SearchResult";
import OrderInfo from "./modules/OrderInfo"
import Other from "./modules/Other"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    HomeModel,
    MyModel,
    TeachListModel,
    UniversityListModel,
    ClassModel,
    LiveModel,
    ClassDetailModel,
    LoginModel,
    CountryDetailModel,
    LiveDetailModel,
    UniversityDetail,
    TeacherDetail,
    CourseList,
    LiveList,
    SearchResult,
    OrderInfo,
    Other
  },
});
