import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    detailData: null,
    number:null, // 订单编号
    effectiveCouponList: [], // 优惠券列表
    QRCodeParams: null, // 获取二维码请求参数
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取购买详情
    async buyDetail(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.buyDetail(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { detailData: results });
      return Promise.resolve();
    },

    // 获取有效优惠券
    async getEffectiveCoupon(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getEffectiveCoupon(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { effectiveCouponList: results });
      return Promise.resolve();
    },

    // 创建订单
    async createOrder(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.createOrder(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", {
        QRCodeParams: results.options,
        number: results.number,
      });
      return Promise.resolve();
    },

    // 订单支付检查
    async orderPayCheck(content, payload) {
      const { succ, results, msg } = await api.orderPayCheck(payload);
      if (!succ) return Promise.reject(msg);
      return Promise.resolve();
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
