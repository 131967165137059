import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    detailData: {},
    videoId: "", // 视频ID
    videoUrl: "", // 视频地址
    reviewList: [], // 评论列表
    reviewCount: 0,
    liveList: [], // 直播列表
    liveUrl: "", // 直播地址
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取直播详情
    async getliveDetal(content, payload) {
      const { detailData } = content.state;
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getliveDetal(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) {
        content.commit("updateState", { detailData: {} });
        return Promise.reject(msg);
      }
      content.commit("updateState", {
        detailData: { ...detailData, ...results },
      });
      return Promise.resolve();
    },

    // 获取相关直播
    async getRelevantLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getRelevantLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { liveList: results });
      return Promise.resolve(msg);
    },
    // 获取视频播放地址
    async getLiveUrl(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getLiveUrl(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { liveUrl: results.linkUrl });
      return Promise.resolve();
    },

    // 添加或取消预约直播
    async addOrDelLiveSubscribe(content, payload) {
      const {detailData} = content.state;
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.addOrDelLiveSubscribe(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState",{
        detailData:{
          ...detailData,
          detail:{
            ...detailData.detail,
            isSubscribe:!detailData.detail.isSubscribe
          }
        }
      })
      return Promise.resolve(msg);
    },

    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },

     //用户行为埋点
     async recordBehavior(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordBehavior(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordBehavior: '' });
      return Promise.resolve();
    },

    //埋点接口(只在详情页记录)
    async recordDetailPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordDetailPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordBehavior: '' });
      return Promise.resolve();
    },
  },
};
