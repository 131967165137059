export default {
  namespaced: true,
  state: {
    breadCrumbsArr: [],
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
    // 改变左侧列表的选中和名字
    addBreadCrumb(state, value) {
      if (!value.meta) return;
      
      if (value.meta.index === 1) {
        state.breadCrumbsArr = [];
      }
      if (value.meta.index === 2) {
        state.breadCrumbsArr = state.breadCrumbsArr.filter(
          (item) => item.meta.index <= 1
        );
      }
      if (value.meta.index === 3) {
        state.breadCrumbsArr = state.breadCrumbsArr.filter(
          (item) => item.meta.index <= 2
        );
      }
      state.breadCrumbsArr.push(value);
      state.breadCrumbsArr.sort((a, b) => a.meta.index - b.meta.index);

      return state;
    },
  },
  actions: {},
};
