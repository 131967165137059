<script>
export default {
  name: "Register",
  data() {
    return {
      isPhoneError: false,
      isPhoneErrorText: "",
      isRead: false, // 是否阅读用户协议
      time: 60, // 重新获取time
      isAuthCodeClick: false,
      isShowAgreement: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("LoginModel/getCountryCodeList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    // 提交注册
    async onRegisterClick() {
      const { loginForm, openid, unionid } = this.$store.state.LoginModel;
      if (!loginForm.mobile) {
        this.isPhoneError = true;
        this.isPhoneErrorText = "请输入手机号码";
      }
      if (!loginForm.code) return this.$message.error("请输入验证码");

      let msg;
      try {
        msg = await this.$store.dispatch("LoginModel/userLogin", {
          ...loginForm,
          wxPcKey: openid,
          unionId: unionid,
          type: 2,
        });
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }

      this.$message({ showClose: true, message: msg, type: "success" });
      this.$store.commit(`LoginModel/updateState`, {
        isShowLogin: false,
        loginForm: {
          ...loginForm,
          mobile: "",
          password: "",
          code: "",
        },
      });
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
      await this.$store.dispatch("MyModel/getNewNoticeNum", {
        datetime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      try {
        await this.$store.dispatch("MyModel/isSign");
      } catch (msg) {
        return;
      }

      // try {
      //   await this.$store.dispatch("LoginModel/checkCode", {
      //     countryCode: loginForm.countryCode,
      //     mobile: loginForm.mobile,
      //     code: loginForm.code,
      //   });
      // } catch (error) {
      //   if (error.code === 1012) {
      //     this.isPhoneError = true;
      //     this.isPhoneErrorText = error.msg;
      //   } else {
      //     this.$message({ showClose: true, message: error.msg, type: "error" });
      //   }
      //   return;
      // }
      // this.$store.commit("LoginModel/updateState", { loginState: "info" });
    },
    // 手机号输入事件
    onPhoneInput(e) {
      const { loginForm } = this.$store.state.LoginModel;
      e.target.value = e.target.value.replace(/[^\d]/g, "");
      const { value } = e.target;
      this.phone = e.target.value;
      this.$store.commit(`LoginModel/updateState`, {
        loginForm: {
          ...loginForm,
          mobile: value,
        },
      });
      if (value !== "") {
        this.isPhoneError = false;
        return;
      }
      // this.isPhoneError = !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
      if (this.isPhoneError) this.isPhoneErrorText = "手机号不能为空";
    },
    // 手机号失去焦点事件
    onPhoneBlur() {
      const { loginForm } = this.$store.state.LoginModel;
      if (!loginForm.mobile) {
        this.isPhoneError = false;
      }
    },
		// 点击获取验证码
		async onAuthCodeClick() {
			const {
				loginForm
			} = this.$store.state.LoginModel;
			if (!loginForm.mobile) return (this.isPhoneError = true);
			this.getGraphicVerification(this.handlerVerify)
		},
		async handlerVerify(ev) {
			const {
				loginForm
			} = this.$store.state.LoginModel;
			console.log(ev.detail, "ev.detail")
			if (ev.detail.ret === 0) {
				// 获取验证码
				try {
					await this.$store.dispatch("LoginModel/sendCode", {
						...loginForm,
						type: "login",
					});
				} catch (msg) {
					this.$message({
						showClose: true,
						message: msg,
						type: "error"
					});
					return;
				}

				this.isAuthCodeClick = true;

				const timer = setInterval(() => {
					if (this.time === 1) {
						this.isAuthCodeClick = false;
						this.time = 60;
						clearInterval(timer);
					} else {
						this.time = this.time - 1;
					}
				}, 1000);
			} else {
				// 验证失败
				// 请不要在验证失败中调用refresh，验证码内部会进行相应处理
			}
		},

    // 输入验证码
    onAuthCodeInput(e) {
      const { loginForm } = this.$store.state.LoginModel;
      e.target.value = e.target.value.replace(/[^\d]/g, "");
      const { value } = e.target;
      this.$store.commit("LoginModel/updateState", {
        loginForm: {
          ...loginForm,
          code: value,
        },
      });
    },

    // 区号选择事件
    onSelectChagne(e) {
      const { loginForm } = this.$store.state.LoginModel;
      const { value } = e.target;
      this.areaCode = e.target.value;
      this.$store.commit("LoginModel/updateState", {
        loginForm: {
          ...loginForm,
          countryCode: value,
        },
      });
    },
  },
  render() {
    const {
      isShowLogin,
      countryCodeList,
      loginForm,
    } = this.$store.state.LoginModel;
    if (!isShowLogin) {
      this.isPhoneError = false;
    }
    return (
      <div class="reset-box">
        <h1>请绑定已有手机号</h1>
        <div class="form-box">
          <div class={`input-box ${this.isPhoneError ? "error" : ""}`}>
            <select onchange={(e) => this.onSelectChagne(e)}>
              {countryCodeList.map((item) => (
                <option value={item.code}>+{item.code}</option>
              ))}
            </select>
            <input
              value={loginForm.mobile}
              class="phone"
              placeholder="请输入手机号"
              oninput={(e) => this.onPhoneInput(e)}
              onblur={this.onPhoneBlur}
              style={{ width: this.isPhoneError ? "60%" : "100%" }}
            />
            {this.isPhoneError ? (
              <span class="error">
                <i class="icon info" />
                {this.isPhoneErrorText}
              </span>
            ) : null}
          </div>
          <div class="input-box" style={{ justifyContent: "space-between" }}>
            <input
              placeholder="请输入验证码"
              oninput={(e) => this.onAuthCodeInput(e)}
            />
            {this.isAuthCodeClick ? (
              <span class="btn gray">重新获取({this.time})</span>
            ) : (
              <span class="btn" onclick={this.onAuthCodeClick}>
                获取验证码
              </span>
            )}
          </div>
        </div>
        <div class="btn-group">
          <span class="btn" onclick={this.onRegisterClick}>
            绑定
          </span>
          <span
            class="text"
            onclick={() =>
              this.$store.commit("LoginModel/updateState", {
                loginState: "register",
              })
            }
          >
            我是新用户，去注册>
          </span>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.reset-box {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  box-sizing: border-box;
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 45px;
  }
  .form-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    .input-box {
      height: 40px;
      background: #f3f6fa;
      border-radius: 8px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      white-space: nowrap;
      .error {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #f84164;
        width: 100%;
        white-space: nowrap;
        .icon.info {
          width: 15px;
          height: 15px;
          background-image: url("../../../assets/images/error-info.png");
          margin-right: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      select {
        background: none;
        border: 0;
        outline: none;
        cursor: pointer;
        margin-right: 10px;
        height: 24px;
      }
      input {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
      }
      input.phone {
        line-height: 24px;
        border-left: 1px solid #c8cdd6;
      }
      span.btn {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #006eeb;
      }
      span.btn.gray {
        color: #a4aab3;
        pointer-events: none;
      }
    }
    .input-box.error {
      background: #fee3e8;
      input {
        color: #f94063;
      }
      span.error {
        color: #f84164;
      }
    }
  }
  .read {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    .icon.check {
      width: 16px;
      height: 16px;
      background-image: url("../../../assets/images/check2.png");
      cursor: pointer;
      margin-right: 9px;
    }
    .icon.check.active {
      background-image: url("../../../assets/images/check.png");
    }
    p {
      color: #1a1a1a;
      font-size: 13px;
      font-weight: 400;
      color: #1a1a1a;
      span {
        color: #006eeb;
        cursor: pointer;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    margin-top: 37.5px;
    .btn {
      width: 100%;
      height: 40px;
      background: #006eeb;
      border-radius: 8px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .text {
      font-size: 13px;
      font-weight: 400;
      color: #006eeb;
      cursor: pointer;
      text-align: right;
      display: block;
      width: 100%;
    }
  }
  .other-box {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    justify-content: flex-start;
    .icon.vx {
      width: 37px;
      height: 37px;
      background-image: url("../../../assets/images/vx.png");
      cursor: pointer;
    }
  }
}
</style>