import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false, // 加载
    searchData: {
      keyword: "", // 搜索关键字
      categoryId: -1, // 名师分类ID
      countryId: -1, // 国家ID
      professionalId: -1, // 专业ID
    },
    teacherList: [], // 名师列表
    teacherTypeList: [], // 名师分类
    countryList: [], // 国家列表
    professionalList: [], // 留学专业
    count: 0,
    nowPage: 1,
    pageSize: 10,
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取名师列表
    async getTeacherList(content, payload) {
      const { succ, results, count, page } = await api.getTeacherList(payload);
      if (!succ) return;
      content.commit("updateState", {
        teacherList: results,
        count,
        nowPage: Number(page),
      });
    },

    // 获取国家列表
    async getCountryList(content, payload) {
      const { succ, results } = await api.getCountryList();
      if (!succ) return;
      const arr = [{ id: -1, name: "全部" }];
      results.map((item) => arr.push({ ...item }));
      content.commit("updateState", {
        countryList: arr,
      });
    },

    // 获取枚举列表
    async getEnumList(content, payload) {
      const { succ, results } = await api.getEnumList();
      if (!succ) return;
      const teacherTypes = results.filter((item) => item.id === 43)[0].childs;
      const professional = results.filter((item) => item.id === 34)[0].childs;

      const professionalarr = [{ value: -1, name: "全部" }];
      const teacherarr = [{ value: -1, name: "全部" }];

      teacherTypes.map((item) => teacherarr.push({ ...item }));
      professional.map((item) =>
        professionalarr.push({ ...item, isActive: false })
      );
      content.commit("updateState", {
        teacherTypeList: teacherarr,
        professionalList: professionalarr,
      });
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
