<script>
import gz from "@assets/images/gz.png";
import mini from "@assets/images/mini.png";
export default {
  name: "FixedComp",
  data() {
    return {
      isShowTop: false,
    };
  },
  mounted() {
    let scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    window.onscroll = () => {
      scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.isShowTop = scrollTop !== 0;
    };
  },
  render() {
    return (
      <div class="fixedBox">
        <a
          class="item"
          target="_bank"
          href="https://live800.eiceducation.com.cn/live800/chatClient/chatbox.jsp?companyID=8940&configID=79"
        >
          <i class="icon service" />
          <span>在线咨询</span>
        </a>
        <div class="item vx">
          <i class="icon vx" />
          <span>关注我们</span>
          <div class="dialog" style={{ top: "-74px", display: "none" }}>
            <div class="main">
              <div class="imgBox">
                <img src={gz} />
              </div>
              <div class="content">
                <p>微信扫一扫或搜索</p>
                <p>
                  关注<span>启德课堂公众号</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="item mini">
          <i class="icon mini" />
          <span>小程序</span>
          <div class="dialog" style={{ top: "-147px", display: "none" }}>
            <div class="main">
              <div class="imgBox">
                <img src={mini} />
              </div>
              <div class="content">
                <p>微信扫一扫或搜索</p>
                <p>
                  关注<span>启德课堂小程序</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.isShowTop ? (
          <div
            class="item"
            style={{ cursor: "pointer" }}
            onclick={() =>
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              })
            }
          >
            <i class="icon top" />
            <span>返回顶部</span>
          </div>
        ) : null}
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.fixedBox {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 16px;
  .item {
    text-decoration: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #dce0e6;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    &:hover {
      span {
        color: #006eeb;
      }
      .dialog {
        display: flex !important;
      }
      .icon.service {
        background-image: url("../assets/images/service1.png");
      }
      .icon.service {
        background-image: url("../assets/images/service1.png");
      }
      .icon.vx {
        background-image: url("../assets/images/vx2.png");
      }
      .icon.mini {
        background-image: url("../assets/images/mini1.png");
      }
      .icon.top {
        background-image: url("../assets/images/top1.png");
      }
    }
    span {
      font-size: 12px;
      color: #8a9099;
      margin-top: 5px;
    }
    .icon.service {
      background-image: url("../assets/images/service2.png");
      width: 26px;
      height: 26px;
    }
    .icon.vx {
      background-image: url("../assets/images/vx1.png");
      width: 30px;
      height: 24px;
    }
    .icon.mini {
      background-image: url("../assets/images/mini2.png");
      width: 24px;
      height: 24px;
    }
    .icon.top {
      background-image: url("../assets/images/top2.png");
      width: 20px;
      height: 18px;
    }
  }
  .item.mini:hover {
    .dialog {
      display: flex !important;
    }
    &::after {
      content: "";
      display: block;
      background-image: url("../assets/images/sanjiao.png");
      position: absolute;
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      left: -19px;
    }
  }

  .item.vx:hover {
    .dialog {
      display: flex !important;
    }
    &::after {
      content: "";
      display: block;
      background-image: url("../assets/images/sanjiao.png");
      position: absolute;
      width: 16px;
      height: 16px;
      background-size: 100% 100%;
      left: -19px;
    }
  }

  .dialog {
    position: absolute;
    left: -206px;
    padding-right: 16px;
    .main {
      width: 190px;
      height: 270px;
      background: #ffffff;
      border: 1px solid #dce0e6;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 40px 35px;
      .imgBox {
        // width: 115px;
        // height: 115px;
        // background: #f3f6fa;
        // border-radius: 8px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        img {
          width: 101px;
          height: 101px;
        }
      }

      .content {
        margin-top: 40px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 21px;
          span {
            color: #006eeb;
          }
        }
      }
    }
  }
}
</style>