import * as api from "../../service/index";
const countryMap = {
  "156": { edu: 172, new: 173, enc: 174 },
  "155": { edu: 169, new: 170, enc: 171 },
  "158": { edu: 178, new: 179, enc: 180 },
  "159": { edu: 181, new: 182, enc: 183 },
  "160": { edu: 184, new: 185, enc: 186 },
  "166": { edu: 202, new: 203, enc: 204 },
  "168": { edu: 208, new: 209, enc: 210 },
  "163": { edu: 193, new: 194, enc: 195 },
  "161": { edu: 187, new: 188, enc: 189 },
  "167": { edu: 205, new: 206, enc: 207 },
  "164": { edu: 196, new: 197, enc: 198 },
  "165": { edu: 199, new: 200, enc: 201 },
  "162": { edu: 190, new: 191, enc: 192 },
  "157": { edu: 175, new: 176, enc: 177 },
};
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    detailData: null, // 详情数据
    eduSystemList: [], //教育体制列表
    newPolicyList: [], // 最新政策列表
    encyclopediaList: [], // 留学百科列表
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取国家详情
    async getCountryDetail(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getCountryDetail(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { detailData: results });
      return Promise.resolve();
    },
    // 获取板块内容
    async getPageInfoList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.getPageInfoList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const eduSystemList = []; //教育体制列表
      const newPolicyList = []; // 最新政策列表
      const encyclopediaList = []; // 留学百科列表
      results.pageInfo.map((item) => {
        item.pageInfoTypeId === countryMap[payload.id].edu && eduSystemList.push(item);
        item.pageInfoTypeId === countryMap[payload.id].new && newPolicyList.push(item);
        item.pageInfoTypeId === countryMap[payload.id].enc && encyclopediaList.push(item);
      });
      content.commit("updateState", {
        eduSystemList,
        newPolicyList,
        encyclopediaList,
      });
      return Promise.resolve();
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
