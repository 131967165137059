<script>
import * as api from "@src/service/index";
export default {
  name: "info",
  data() {
    return {
      password: "", // 密码，用于检验再次输入密码是否正确
      againPwd: "", // 再次输入密码
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("LoginModel/getCountryList");
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    // 昵称输入事件
    onNickNameInput(e) {
      const { registerForm } = this.$store.state.LoginModel;
      const { value } = e.target;
      this.$store.commit(`LoginModel/updateState`, {
        registerForm: {
          ...registerForm,
          nickname: value,
        },
      });
    },
    // 密码输入事件
    onPwdInput(e) {
      const { registerForm } = this.$store.state.LoginModel;
      const { value } = e.target;
      this.password = value;
      this.$store.commit(`LoginModel/updateState`, {
        registerForm: {
          ...registerForm,
          password: value,
        },
      });
    },
    // 再次输入密码
    onAgainPwdInput(e) {
      const { value } = e.target;
      this.againPwd = value;
    },
    async onSubmit() {
      const {registerForm} = this.$store.state.LoginModel;
      if (
        !/^[A-Za-z0-9~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]]{8,20}$/.test(
          this.password
        )
      ) {
        this.$message({
          showClose: true,
          message: "密码长度为8-20位",
          type: "error",
        });
        return;
      }

      if (this.againPwd !== this.password) {
        this.$message({
          showClose: true,
          message: "两次密码输入不同",
          type: "error",
        });
        return;
      }
      
      let msg;
      if(localStorage.getItem("channelmed")){
        registerForm.channelmed  = localStorage.getItem("channelmed");
      }
      if(localStorage.getItem("localUrl")){
        registerForm.localUrl = localStorage.getItem("localUrl");
      }
      try {
        msg = await this.$store.dispatch(
          "LoginModel/userRegister",
          registerForm
          );
      } catch (msg) {
        this.$message({ showClose: true, message: msg, type: "error" });
        return;
      }
      this.$message({ showClose: true, message: msg, type: "success" });
      this.$store.commit(`LoginModel/updateState`, {
        isShowLogin: false,
        registerForm: {
          mobile: "", // 手机号
          nickname: "", // 昵称
          code: "", //验证码
          password: "", //密码
          countryId: "", // 国家ID
          countryCode: "", // 国家区号
          channelmed: "", // 推广渠道
          localUrl: "", // 推广原始url
        }
      });
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            this.$message({ showClose: true, message: error.msg, type: "error" });
            window.$cookies.set("token",'')
          }
          return;
        }
      await this.$store.dispatch("MyModel/getNewNoticeNum", {
        datetime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
      });
    },
    onCountrySelect(e) {
      const { value } = e.target;
      const { registerForm } = this.$store.state.LoginModel;
      this.$store.commit(`LoginModel/updateState`, {																																													
        registerForm: {
          ...registerForm,
          countryId: value,
        },
      });
    },
  },
  render() {
    const { countryList } = this.$store.state.LoginModel;
    return (
      <div class="reset-box">
        <h1>请完善个人信息</h1>
        <div class="form-box">
          <div class="input-box">
            <input
              placeholder="请输入昵称"
              autocomplete="off"
              name="nickname"
              style={{ width: "100%" }}
              oninput={(e) => this.onNickNameInput(e)}
            />
          </div>
          <div class="input-box">
            <input
              type="password"
              autocomplete="off"
              name="password"
              placeholder="请输入密码"
              style={{ width: "100%" }}
              oninput={(e) => this.onPwdInput(e)}
            />
          </div>
          <div class="input-box">
            <input
              oninput={(e) => this.onAgainPwdInput(e)}
              placeholder="请再次输入密码"
              autocomplete="off"
              name="aginpwd"
              type="password"
              style={{ width: "100%" }}
            />
          </div>
          <div class="input-box">
            <select style={{ width: "100%" }} onChange={this.onCountrySelect}>
              <option value="">选择您的意向留学国家</option>
              {countryList.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div class="btn-group">
          <span class="btn" onclick={this.onSubmit}>
            完成
          </span>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.reset-box {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  box-sizing: border-box;
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 45px;
  }
  .form-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    select {
      font-size: 14px;
      font-weight: 400;
      color: #a4aab3;
    }
    .input-box {
      height: 40px;
      background: #f3f6fa;
      border-radius: 8px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      select {
        background: none;
        border: 0;
        outline: none;
        cursor: pointer;
        margin-right: 10px;
        height: 24px;
      }
      input {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #a4aab3;
          font-size: 14px;
        }

        & ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #a4aab3;
          font-size: 14px;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #a4aab3;
          font-size: 14px;
        }
      }
      input.phone {
        line-height: 24px;
        border-left: 1px solid #c8cdd6;
      }
      span.btn {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #006eeb;
      }
    }
    .input-box.error {
      background: #fee3e8;
      input {
        color: #f94063;
      }
      span.error {
        color: #f84164;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    .btn {
      width: 100%;
      height: 40px;
      background: #006eeb;
      border-radius: 8px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .text {
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
      color: #006eeb;
      cursor: pointer;
    }
  }
}
</style>