<script>
export default {
  name: "DialogComp",
  props: {
    data: Object,
  },
  render() {
    return (
      <div class="shadow">
        <div class="main" style={this.data.style}>
          <i class="icon close" onclick={() => this.data.onclose()} />
          {this.data.title ? <div class="title">{this.data.title}</div> : null}
          <div class="content">{this.data.content()}</div>
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  .main {
    background: #ffffff;
    border-radius: 8px;
    padding: 30px 0px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .icon.close {
      display: block;
      width: 31px;
      height: 31px;
      background-image: url("../assets/images/close.png");
      background-size: 100%;
      background-repeat: no-repeat;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .title {
      font-size: 30px;
      font-weight: 600;
      color: #1a1a1a;
      text-align: center;
    }
  }
}
</style>