<script>
// 用户协议

import DialogComp from "@components/Dialog";
export default {
  name: "Agreement",
  props: {
    isShow: Boolean,
    closeFunc: {
      type: Function,
    },
  },
  render() {
    const dialogData = {
      onclose: this.closeFunc,
      style: { width: "700px", padding: "39px 24px" },
      content: () => (
        <div class="agreement">
          <h1>启德课堂会员服务协议</h1>
          <div class="main">
            <h2>【首部及导言】</h2>
            <p>
              欢迎访问启德课堂网站（电脑端：https://www.eicchannel.com，移动端：https://m.eicchannel.com，小程序：微信小程序内搜索“启德课堂”）。启德课堂网站（以下简称“本网站”）是启德教育集团下北京市朝阳区启德学府出国培训学校（以下简称“本学校”）运营的商业网站，为依法保障本网站及相关版权所有者的合法权利，本学校特作出以下法律声明。本法律声明阐述之条款和条件适用于所有访问和使用本网站的个人或机构（以下简称“用户”）。
              用户在接受本网站的服务之前，请务必仔细阅读本条款并同意本声明。用户直接或通过各类方式间接使用本网站服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容，若用户对本声明的任何条款有异议，请停止使用本网站所提供的全部服务。
            </p>
            <div class="list">
              <h3>1. 服务说明</h3>
              <p>本网站向您提供包括但不限于如下服务：</p>
              <p>
                1.1
                启德课堂网站：https://www.eicchannel.com（及其他由本学校运营的任何网站）
              </p>
              <p>1.2 启德课堂用户个人中心、积分</p>
              <p>1.3启德课堂提供给您的各类增值服务</p>
              <p>
                1.4启德课堂提供的其他技术和/或服务，以启德课堂实际提供的为准
              </p>
              <p>
                本网站有权提前向用户公告（包括但不限于弹出公告、网站公告、系统消息）以修改、替换、升级与本网站服务相关的任何软件或页面。如果用户不同意相关变更，请直接停止使用本网站。
              </p>
              <p>
                本网站作为启德教育提供咨询服务的重要平台和沟通渠道，如果用户通过本网站提交自己的手机号，无论任何方式（注册信息、点击预约咨询按钮、咨询网站客服等），均视为用户需要相关咨询服务，会在1-3个工作日内安排客服进行电话回访。
              </p>
              <h3>2. 版权和商标</h3>
              <p>
                本网站上可观看和可见的所有资料都受到知识产权法的保护，本网站和本学校拥有本网站相关网页内所有资料的版权，或与本网站相关网页内的资料提供者共同拥有该网页内资料的版权所有，此等资料都是受到著作权法和版权法的法律保护。未经本学校或本网站的明确书面许可，对于本网站上的任何内容，任何机构或个人不得擅自对其进行复制或在非本学校所属的服务器上做镜像，或将其用作商业用途，任何机构或个人没有获得本网站或各自的版权所有者明确的书面同意下，不得分发、修改、散布、再使用、再传递或使用本网站的内容用于任何公众商业用途。
              </p>
              <p>
                本网站LOGO商标或标识都是本学校的商品及服务商标和商号。所有其他本网站没有主张权利的商标和产品名称则可能是它们各自所有权人的商标或注册商标。未获得本学校或其他在本网站上有权使用商标的第三方的书面同意和授权，不得使用或展示本网站所刊载之任何商标。
              </p>
              <p>
                转载和以任何形式使用本网站所载数据、图片与文字应以中文名称并网站域名注明出处，即“来源：启德课堂网站（电脑端：https://www.eicchannel.com，移动端：https://m.eicchannel.com，小程序：微信小程序内搜索“启德课堂”）”，并事先获得本网站书面授权批准。我们保留追究未经授权使用者法律责任的权利。
              </p>
              <p>
                用户以任何方式使用本网站的内容和数据（包括但不限于发表、宣传介绍、转载、浏览及利用本网站或本网站用户发布内容）的过程中，不得以任何方式利用本网站直接或间接从事违反中国法律以及社会公德的行为，且用户应当恪守下述承诺：
              </p>
              <p> 2.1 发布、转载或提供的内容符合中国法律、社会公德； </p>
              <p> 2.2 不得干扰、损害和侵犯本网站的各种合法权利与利益；</p>
              <p>
                2.3 遵守本网站以及与之相关的网络服务的协议、指导原则、管理细则等
              </p>
              <p>本网站有权对违反上述承诺的内容予以删除。</p>
              <h3>3. 账号安全</h3>
              <p>
                {" "}
                用户通过手机号注册成为本网站会员，获得本网站提供的相应服务内容，账号信息的真实性、正确性、完整性，以及账号下的一切行为由用户承担全部责任。用户仅享有账号及账号项下由本网站提供的虚拟产品及服务的使用权，账号及所获得的虚拟产品及服务的所有权归本网站所有（法律法规另有规定的除外）。未经本网站书面同意，用户不得以任何形式处置账号的使用权（包括但不限于赠与、出借、转让、销售、抵押、继承、许可他人使用）。
              </p>
              <p>
                用户需妥善保管个人账号、密码等信息，因用户个人行为导致信息泄露，损害用户的权益，本网站概不负责。
              </p>
              <h3>4. 隐私政策</h3>
              <p>
                本网站保护用户的个人信息及个人隐私。个人信息包括但不限于用户在注册、登录、使用本网站内容时预留的、提供的包括但不限于手机号、姓名、性别、出生日期、学历层次、留学意向等以电子或其他方式记录的信息。本信息仅用于识别用户身份、推送用户需要的课程内容，未经用户允许，不会提供给启德教育之外的任何第三方公司、机构或个人。
              </p>
              <p>
                本网站支持用户使用第三方平台的账号（微信账号/QQ账号）进行快速登录，使用第三方平台的账号登录的，将根据用户的授权获取该第三方账号下的相关信息（包括：用户名、昵称、头像等）以及身份验证信息。本网站承诺，收集上述信息是用于提供账号登录服务以及保障账号安全，防范安全风险。如拒绝授权此类信息，则用户将无法使用第三方平台的账号登录本网站。
              </p>
              <p>
                为避免个人信息被泄露，用户应当以正确正规的途径访问本网站，否则造成的个人信息泄露不在本网站的负责范围内。
              </p>
              <h3>5. 免责声明</h3>
              <p>
                本网站的一切内容都是为了更好地提供服务，本网站不保证所有信息、文本、图形、链接及其它项目的绝对准确性和完整性，内容仅供使用参照，本网站对任何使用或提供本网站信息的商业活动及其风险不承担任何责任。
              </p>
              <p>
                本网站所发布的信息中可能没有包含与本学校及其业务有关的最新信息，对于本网站所发布的信息，本学校不声明或保证其内容之正确性或完整性。
              </p>
              <p>
                本学校对本网站提供的服务不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的之适用性及未侵害他人权利等担保或保证。
              </p>
              <p>
                用户在本网站发表的内容（包含但不限于本网站目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表本网站的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律责任，本学校不承担任何法律及连带责任。
              </p>
              <p>
                用户在本网站发布侵犯他人合法权益或涉嫌犯罪的内容，本网站有权予以删除，并保留移交公安机关处理的权利。
              </p>
              <p>
                个人或单位如认为本网站上存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与本学校取得联系，以便本学校迅速做出处理。
              </p>
            </div>
          </div>
        </div>
      ),
    };
    return <DialogComp v-show={this.isShow} data={dialogData} />;
  },
};
</script>
<style lang="less" scoped>
.agreement {
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #006eeb;
    padding-bottom: 23px;
    text-align: center;
  }
  .main {
    height: 434px;
    overflow-y: scroll;
    padding-right: 14px;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #99c5f7;
    }
    h2,
    p,
    h3 {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 28px;
    }
    .list {
      margin-top: 40px;
      h3 {
        margin-top: 10px;
      }
    }
  }
}
</style>