<script>
import Agreement from "@components/Agreement";
export default {
  name: "vxlogin",
  data() {
    return {
      isRead: false,
      isShowAgreement: false,
    };
  },
  async mounted() {
    const { query } = this.$route;
    let params = "";
    Object.keys(query).map((key, index) => {
      if (key === "code" || key === "state") return;
      params += `${key}=${query[key]}&`;
    });
    const url = `https://www.eicchannel.com${this.$route.path}?${params}`;
    try {
      await this.$store.dispatch("LoginModel/getAuthorizeParam", {
        redirect_uri: url,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }

    const { vxAuthorData } = this.$store.state.LoginModel;
    new window.WxLogin({
      self_redirect: false,
      id: "login_container", //div的id
      appid: vxAuthorData.appid,
      scope: vxAuthorData.scope, //写死
      state: vxAuthorData.state,
      redirect_uri: vxAuthorData.redirect_uri,
    });
  },
  render() {
    return (
      <div class="reset-box">
        <div id="login_container" class="login_container"></div>
        {/**<div class="read">
          <i
            class={`icon check ${this.isRead ? "active" : ""}`}
            onclick={() => (this.isRead = !this.isRead)}
          />
           <p>
            我已阅读并同意
            <span onclick={() => (this.isShowAgreement = true)}>
              《用户服务协议》
            </span>
          </p>  
        </div>*/}
        <div class="btn-group">
          <span
            class="text"
            onclick={() =>
              this.$store.commit("LoginModel/updateState", {
                loginState: "login",
              })
            }
          >
            返回上一步
          </span>
        </div>
        <Agreement
          isShow={this.isShowAgreement}
          closeFunc={() => (this.isShowAgreement = false)}
        />
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.reset-box {
  display: flex;
  flex-direction: column;
  padding: 0 35px;
  box-sizing: border-box;
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 45px;
  }
  .login_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .read {
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    .icon.check {
      width: 16px;
      height: 16px;
      background-image: url("../../../assets/images/check2.png");
      cursor: pointer;
      margin-right: 9px;
    }
    .icon.check.active {
      background-image: url("../../../assets/images/check.png");
    }
    p {
      color: #1a1a1a;
      font-size: 13px;
      font-weight: 400;
      color: #1a1a1a;
      span {
        color: #006eeb;
        cursor: pointer;
      }
    }
  }
  .btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    .btn {
      width: 100%;
      height: 40px;
      background: #006eeb;
      border-radius: 8px;
      line-height: 40px;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .text {
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
      color: #006eeb;
      cursor: pointer;
    }
  }
  .other-box {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    justify-content: flex-start;
    .icon.vx {
      width: 37px;
      height: 37px;
      background-image: url("../../../assets/images/vx.png");
      cursor: pointer;
    }
  }
}
</style>