<script>
export default {
  name: "searchDialog",
  props: {
    searchclose: {
      type: Function,
    },
  },
  data() {
    return {
      historyList: [],
    };
  },
  async mounted() {
    this.historyList = JSON.parse(localStorage.getItem("historySearch"));
	this.historyList.reverse();
	this.historyList.splice(10,this.historyList.length-10);
    try {
      await this.$store.dispatch("MyModel/getHotKeyList", {
        page: 1,
        pageSize: 5, 
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
  },
  methods: {
    itemClick(record) {
      this.searchclose();
      this.$router.push({ path: `/search/result?keyword=${record.name}` });

      if (localStorage.getItem("historySearch")) {
        const arr = JSON.parse(localStorage.getItem("historySearch"));
        arr.push(record.name);
        localStorage.setItem("historySearch", JSON.stringify(arr));
      } else {
        localStorage.setItem("historySearch", JSON.stringify([record.name]));
      }
    },
  },
  render() {
    const { hotKeyList } = this.$store.state.MyModel;
    return (
      <div class="search-box">
        <div class="search-dialog">
          <div class="hot">
            <h3>
              <i class="icon hot" />
              热门搜索
            </h3>
            <div class="list">
              {hotKeyList.map((item, index) => (
                <div class="item" onclick={() => this.itemClick(item)}>
                  <i
                    class={`index ${index === 0 && "first"} ${
                      index === 1 && "second"
                    } ${index === 2 && "third"}`}
                  >
                    {index + 1}
                  </i>
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          </div>
          {window.$cookies.get("token") ? (
            <div class="history">
              <div class="title-box">
                <h3>
                  <i class="icon iconhistory" />
                  搜索历史
                </h3>
                <span
                  onclick={() => {
                    localStorage.removeItem("historySearch");
                    this.historyList = [];
                  }}
                >
                  清空搜索历史
                </span>
              </div>
              {localStorage.getItem("historySearch") ? (
                <div class="list">
                  {this.historyList.map((item) => (
                    <p
                      onclick={() => {
                        this.searchclose();
                        this.$router.push({
                          path: `/search/result?keyword=${item}`,
                        });
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
};
</script>
<style lang="less" scoped>
.search-box {
  padding-top: 20px;
  z-index: 999;
  top: 30px;
  right: 0;
  position: absolute;
}
.search-dialog {
  padding: 15px;
  box-shadow: -1px 1px 12px 0px rgba(0, 0, 0, 0.13);
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  .hot {
    width: 265px;
    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: #1a1a1a;
      margin-bottom: 20px;
      .icon.hot {
        background-image: url("../assets/images/fire@2x.png");
        width: 17px;
        height: 21px;
        margin-right: 8px;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      .item {
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        font-weight: 400;
        line-height: 18px;
        position: relative;
        color: #5c6066;
        .index {
          display: block;
          font-style: normal;
          color: #ffffff;
          font-size: 14px;
          width: 18px;
          height: 18px;
          border-radius: 4px;
          background: #d6dae0;
          margin-right: 7px;
          text-align: center;
          line-height: 18px;
        }
        .index.first {
          background: #ff833e;
        }
        .index.second {
          background: #ffa878;
        }
        .index.third {
          background: #ffc19e;
        }
      }
      .item.up {
        &::before {
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          width: 19px;
          height: 19px;
          background-image: url("../assets/images/up-small (1)@2x.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .history {
    border-left: 1px solid #e6e8ec;
    padding-left: 14px;
    width: 265px;
    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      h3 {
        font-size: 16px;
        font-weight: bold;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        .icon.iconhistory {
          background-image: url("../assets/images/search-history.png");
          width: 20px;
          height: 18px;
          margin-right: 8px;
        }
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #a4aab3;
        cursor: pointer;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 17px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        color: #5c6066;
      }
    }
  }
}
</style>