<script>
	import avatar from "@assets/images/avatar.png";
	import {
		urlNocanshu
	} from "../../../utils/index.js";
	export default {
		name: "MainComp",
		data() {
			return {
				nowTab: 2, // 2验证码登录  1 密码登录
				isPhoneError: false,
				countryList: [], // 国家区号列表
				time: 60, // 重新获取time
				isAuthCodeClick: false, // 是否点击获取验证码
				isClickLogin: false, //防止频繁点击登录
			};
		},
		async mounted() {
			try {
				await this.$store.dispatch("LoginModel/getCountryCodeList");
			} catch (msg) {
				this.$message({
					showClose: true,
					message: msg,
					type: "error"
				});
				return;
			}
		},
		methods: {
			togggleTab(value) {
				this.nowTab = value;
				this.isPhoneError = false;
				const {
					loginForm
				} = this.$store.state.LoginModel;
				this.$store.commit(`LoginModel/updateState`, {
					loginForm: {
						...loginForm,
						mobile: "",
						code: "",
					},
				});
			},
			// 手机号输入事件
			onPhoneInput(e) {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				e.target.value = e.target.value.replace(/[^\d]/g, "");
				const {
					value
				} = e.target;
				this.phone = e.target.value;
				this.$store.commit(`LoginModel/updateState`, {
					loginForm: {
						...loginForm,
						mobile: value,
					},
				});
				if (value !== "") {
					this.isPhoneError = false;
					return;
				}
				// this.isPhoneError = !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value);
			},
			// 区号选择事件
			onSelectChagne(e) {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				const {
					value
				} = e.target;
				this.areaCode = e.target.value;
				this.$store.commit(`LoginModel/updateState`, {
					loginForm: {
						...loginForm,
						countryCode: value,
					},
				});
			},
			// 输入验证码
			onAuthCodeInput(e) {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				e.target.value = e.target.value.replace(/[^\d]/g, "");
				const {
					value
				} = e.target;
				this.$store.commit(`LoginModel/updateState`, {
					loginForm: {
						...loginForm,
						code: value,
					},
				});
			},
			// 密码输入事件
			onPwdInput(e) {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				const {
					value
				} = e.target;
				this.$store.commit(`LoginModel/updateState`, {
					loginForm: {
						...loginForm,
						password: value,
					},
				});
			},
			// 点击获取验证码
			async onAuthCodeClick() {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				const {
					mobile,
					countryCode
				} = loginForm;
				if (!mobile) return (this.isPhoneError = true);
				this.getGraphicVerification(this.handlerVerify)
			},

			async handlerVerify(ev) {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				const {
					mobile,
					countryCode
				} = loginForm;
				console.log(ev.detail, "ev.detail")
				if (ev.detail.ret === 0) {
					// 获取验证码
					try {
						await this.$store.dispatch("LoginModel/sendCode", {
							...loginForm,
							type: "login",
							ticket: ev.detail.ticket,
							randstr: ev.detail.randstr,
							clentType: 2,
						});
					} catch (msg) {
						this.$message({
							showClose: true,
							message: msg,
							type: "error"
						});
						return;
					}

					this.isAuthCodeClick = true;

					const timer = setInterval(() => {
						if (this.time === 1) {
							this.isAuthCodeClick = false;
							this.time = 60;
							clearInterval(timer);
						} else {
							this.time = this.time - 1;
						}
					}, 1000);
				} else {
					// 验证失败
					// 请不要在验证失败中调用refresh，验证码内部会进行相应处理
				}
			},

			async onLoginClick() {
				const {
					loginForm
				} = this.$store.state.LoginModel;
				if (this.nowTab === 2) loginForm.type = 2; // 验证码登录
				if (this.nowTab === 1) loginForm.type = 1; // 密码登录
				if (this.isPhoneError) return;
				if (this.isClickLogin) return;

				this.isClickLogin = true;
				let msg;
				try {
					msg = await this.$store.dispatch("LoginModel/userLogin", loginForm);
				} catch (msg) {
					this.$message({
						showClose: true,
						message: msg,
						type: "error"
					});
					this.isClickLogin = false;
					return;
				}

				this.isClickLogin = false;
				this.$message({
					showClose: true,
					message: msg,
					type: "success"
				});
				this.$store.commit(`LoginModel/updateState`, {
					isShowLogin: false,
					loginForm: {
						...loginForm,
						mobile: "",
						password: "",
						code: "",
					},
				});
				try {
					await this.$store.dispatch("MyModel/getUserInfo");
				} catch (error) {
					if (error.code == 1016) {
						this.$message({
							showClose: true,
							message: error.msg,
							type: "error"
						});
						window.$cookies.set("token", '')
					}
					return;
				}
				await this.$store.dispatch("MyModel/getNewNoticeNum", {
					datetime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
				});

				try {
					await this.$store.dispatch("MyModel/isSign");
				} catch (msg) {
					return;
				}
				//记录用户点击登录行为
				try {
					await this.$store.dispatch("MyModel/recordBehavior", {
						app: 3,
						action: 4,
						uvId: window.$cookies.get("openId") || window.$cookies.get("uuid"),
						pageurl: urlNocanshu(),
						accountId: window.$cookies.get("accountId") || ''
					});
				} catch (msg) {
					// this.$message({ showClose: true, message: msg, type: "error" });
					return;
				}
			},
			async onRegisterClick() {
				//记录用户点击注册行为
				try {
					await this.$store.dispatch("MyModel/recordBehavior", {
						app: 3,
						action: 4,
						uvId: window.$cookies.get("openId") || window.$cookies.get("uuid"),
						pageurl: urlNocanshu(),
						accountId: window.$cookies.get("accountId") || ''
					});
				} catch (msg) {
					// this.$message({ showClose: true, message: msg, type: "error" });
					return;
				}
			},
			//记录用户点击vx登录行为
			async vxLoginClick() {
				try {
					await this.$store.dispatch("MyModel/recordBehavior", {
						app: 3,
						action: 4,
						uvId: window.$cookies.get("openId") || window.$cookies.get("uuid"),
						pageurl: urlNocanshu(),
						accountId: window.$cookies.get("accountId") || ''
					});
				} catch (msg) {
					// this.$message({ showClose: true, message: msg, type: "error" });
					return;
				}
			},
		},
		render() {
			const {
				loginForm,
				countryCodeList
			} = this.$store.state.LoginModel;
			return ( <
				div class = "main-box" >
				<
				img src = {
					avatar
				}
				/> <
				div class = "tabbar" >
				<
				span class = {
					this.nowTab === 2 ? "active" : ""
				}
				onclick = {
					() => this.togggleTab(2)
				} >
				<
				i class = "icon tag" / >
				验证码登录 <
				/span> <
				span class = {
					this.nowTab === 1 ? "active" : ""
				}
				onclick = {
					() => this.togggleTab(1)
				} >
				<
				i class = "icon tag" / >
				密码登录 <
				/span> <
				/div> {
					this.nowTab === 2 && ( <
						div class = "form-box" >
						<
						div class = {
							`input-box ${this.isPhoneError ? "error" : ""}`
						} >
						<
						select onchange = {
							(e) => this.onSelectChagne(e)
						} > {
							countryCodeList.map((item) => ( <
								option value = {
									item.code
								} > +{
									item.code
								} < /option>
							))
						} <
						/select> <
						input value = {
							loginForm.mobile
						}
						class = "phone"
						style = {
							{
								width: this.isPhoneError ? "60%" : "100%"
							}
						}
						placeholder = "请输入手机号"
						oninput = {
							(e) => this.onPhoneInput(e)
						}
						/> {
							this.isPhoneError ? ( <
								span class = "error" >
								<
								i class = "icon info" / >
								手机号不能为空 <
								/span>
							) : null
						} <
						/div> <
						div class = "input-box"
						style = {
							{
								justifyContent: "space-between"
							}
						} >
						<
						input value = {
							loginForm.code
						}
						placeholder = "请输入验证码"
						oninput = {
							(e) => this.onAuthCodeInput(e)
						}
						/> {
							this.isAuthCodeClick ? ( <
								span class = "btn gray" > 重新获取({
									this.time
								}) < /span>
							) : ( <
								span class = "btn"
								onclick = {
									this.onAuthCodeClick
								} >
								获取验证码 <
								/span>
							)
						} <
						/div> <
						/div>
					)
				} {
					this.nowTab === 1 && ( <
						div class = "form-box" >
						<
						div class = {
							`input-box ${this.isPhoneError ? "error" : ""}`
						} >
						<
						input value = {
							loginForm.mobile
						}
						style = {
							{
								width: this.isPhoneError ? "80%" : "100%"
							}
						}
						placeholder = "请输入手机号"
						oninput = {
							(e) => this.onPhoneInput(e)
						}
						/> {
							this.isPhoneError ? ( <
								span class = "error" >
								<
								i class = "icon info" / >
								手机号不能为空 <
								/span>
							) : null
						} <
						/div> <
						div class = "input-box"
						style = {
							{
								justifyContent: "space-between"
							}
						} >
						<
						input type = "password"
						value = {
							loginForm.password
						}
						placeholder = "请输入密码"
						oninput = {
							(e) => this.onPwdInput(e)
						}
						/> <
						span class = "btn"
						onclick = {
							() =>
							this.$store.commit("LoginModel/updateState", {
								loginState: "reset",
							})
						} >
						忘记密码？ <
						/span> <
						/div> <
						/div>
					)
				} <
				div class = "btn-group" >
				<
				span class = "btn"
				onclick = {
					this.onLoginClick
				} >
				登录 <
				/span> <
				span class = "text"
				onclick = {
					this.onRegisterClick
				}
				onclick = {
					() =>
					this.$store.commit("LoginModel/updateState", {
						loginState: "register",
					})
				} >
				新用户注册 <
				/span> <
				/div> <
				div class = "other-box" >
				其他登录方式： <
				i class = "icon vx"
				onclick = {
					this.vxLoginClick
				}
				onclick = {
					() =>
					this.$store.commit("LoginModel/updateState", {
						loginState: "vxlogin",
					})
				}
				/> <
				/div> <
				/div>
			);
		},
	};
</script>
<style lang="less" scoped>
	.main-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 35px;

		img {
			width: 78px;
			height: 78px;
			object-fit: cover;
			border-radius: 50%;
			margin-bottom: 24px;
		}

		.tabbar {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding: 0 25px;
			margin-bottom: 26px;
			box-sizing: border-box;

			span {
				display: flex;
				align-items: center;
				font-weight: 400;
				color: #a4aab3;
				cursor: pointer;
				font-size: 18px;

				&::before {
					display: block;
					content: "";
					width: 6px;
					height: 8px;
					background-size: 100%;
					background-repeat: no-repeat;
					background-image: url("../../../assets/images/arrow-right2.png");
					margin-right: 6px;
				}
			}

			span.active {
				color: #1a1a1a;

				&::before {
					background-image: url("../../../assets/images/arrow-right.png");
				}
			}
		}

		.form-box {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			.input-box {
				height: 40px;
				background: #f3f6fa;
				border-radius: 8px;
				padding: 0 16px;
				display: flex;
				align-items: center;
				margin-bottom: 12px;

				.error {
					display: flex;
					width: 100%;
					align-items: center;
					font-size: 14px;
					font-weight: 400;
					color: #f84164;

					.icon.info {
						width: 15px;
						height: 15px;
						background-image: url("../../../assets/images/error-info.png");
						margin-right: 4px;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				select {
					background: none;
					border: 0;
					outline: none;
					cursor: pointer;
					margin-right: 10px;
					height: 24px;
				}

				input {
					padding: 0 10px;
					font-size: 14px;
					font-weight: 400;
					color: #a4aab3;
					width: 55%;
				}

				input.phone {
					line-height: 24px;
					border-left: 1px solid #c8cdd6;
				}

				span.btn {
					cursor: pointer;
					font-size: 14px;
					font-weight: 400;
					color: #006eeb;
				}

				span.btn.gray {
					color: #a4aab3;
					pointer-events: none;
				}
			}

			.input-box.error {
				background: #fee3e8;

				input {
					color: #f94063;
				}

				span.error {
					color: #f84164;
				}
			}
		}

		.btn-group {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			margin-bottom: 25px;

			.btn {
				width: 100%;
				height: 40px;
				background: #006eeb;
				border-radius: 8px;
				line-height: 40px;
				font-size: 16px;
				color: #ffffff;
				font-weight: 400;
				text-align: center;
				cursor: pointer;
				margin-bottom: 10px;
			}

			.text {
				font-size: 13px;
				font-weight: 400;
				text-decoration: underline;
				color: #006eeb;
				cursor: pointer;
			}
		}

		.other-box {
			width: 100%;
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: 400;
			color: #000000;
			justify-content: flex-start;

			.icon.vx {
				width: 37px;
				height: 37px;
				background-image: url("../../../assets/images/vx.png");
				cursor: pointer;
			}
		}
	}
</style>
