<script>
import HeaderComp from "./components/HeaderComp";
import FooterComp from "./components/FooterComp";
import FixedComp from "./components/FixedComp";
import LeftTabbox from "./components/LeftTabbox";
import { GetQueryString, _makeUID } from "./utils/index.js";
export default {
  name: "App",
  data() {
    return {
      showState: false,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload() {
      this.showState = false;
      this.$nextTick(() => {
        this.showState = true;
      });
    },
    // 获取链接地址中的参数
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
  watch: {
    async $route(to, from) {
      this.reload();
      this.$store.commit("Other/addBreadCrumb", to);

      const { code } = to.query;
      window.$cookies.set("code", code);
      if (code) {
        try {
          await this.$store.dispatch("LoginModel/getAccessToken", { code });
        } catch (err) {
          this.$message({ showClose: true, message: err.msg, type: "error" });
          this.$store.commit("LoginModel/updateState", {
            loginState: "login",
            isShowLogin: true,
          });
          return;
        }
        const { openid, unionid, loginForm } = this.$store.state.LoginModel;
        try {
          await this.$store.dispatch("LoginModel/userLogin", {
            wxPcKey: openid,
            unionId: unionid,
            type: 6,
          });
        } catch (msg) {
          // this.$message({ showClose: true, message: msg, type: "error" });
          this.$store.commit("LoginModel/updateState", {
            loginState: "bindPhone",
            isShowLogin: true,
            loginForm: {
              ...loginForm,
              wxPcKey: openid,
              unionId: unionid,
            },
          });
          return;
        }

        try {
          await this.$store.dispatch("MyModel/getUserInfo");
        } catch (error) {
          if (error.code == 1016) {
            this.$message({
              showClose: true,
              message: error.msg,
              type: "error",
            });
            window.$cookies.set("token", "");
            this.$store.commit("LoginModel/updateState", {
              isShowLogin: true,
              loginState: "login",
            });
          }
          return;
        }
      }
    },
  },
  created: function () {
    var channelmed = GetQueryString("channelmed");
    var isExistChannelmed = localStorage.getItem("channelmed");
    var isExistuuid = window.$cookies.get("uuid");
    var localUrl = window.location.href;
    if (channelmed) {
      window.$cookies.set("datachannelmed", channelmed);
    }
    //执行pc端未使用微信登录生成唯一标识的方法
    if (isExistuuid) {
      return;
    } else {
      _makeUID();
    }
    //数据跟踪
    if (!channelmed && !isExistChannelmed) {
      window.$cookies.set("datachannelmed", channelmed);
      var dataurl = "";
      window.$cookies.set("dataurl", dataurl);
    }
    if (channelmed) {
      window.$cookies.set("datachannelmed", channelmed);
      var dataurl = window.location.href;
      window.$cookies.set("dataurl", dataurl);
    }
    if (!channelmed && isExistChannelmed) {
      return;
    }

    //获取推广参数
    if (channelmed) {
      localStorage.setItem("channelmed", channelmed);
      localStorage.setItem("localUrl", localUrl);
      return;
    } else if (isExistChannelmed) {
      return;
    }
    localStorage.setItem("channelmed", channelmed);
    localStorage.setItem("localUrl", localUrl);
  },
  render() {
    return (
      <div id="app">
        <HeaderComp />
        <LeftTabbox />
        {this.showState ? <router-view /> : null}
        <FixedComp />
        <FooterComp />
      </div>
    );
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  background: #f3f6fa;
  font-family: Microsoft YaHei;
  /*user-select: none;禁止复制*/
}
input,
textarea {
  border: 0;
  outline: none;
  background: none;
}
video {
  outline: none;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}
.slide-fade-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-fade-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.el-carousel__indicators {
  display: none;
}
.icon {
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.el-progress__text {
  display: none !important;
}
</style>
