<script>
import MainComp from "./Main";
import DialogComp from "@components/Dialog";
import ResetComp from "./reset";
import RegisterComp from "./Register";
import InfoComp from "./Info";
import VXLogin from "./VXLogin"
import BindPhone from "./bindPhone"
import Loading from "../Loading";
export default {
  name: "login",
  render() {
    const {
      loginState,
      isShowLogin,
      isShowLoading,
      registerForm,
      loginForm,
    } = this.$store.state.LoginModel;
    const dialogData = {
      onclose: () => {
        this.$store.commit("LoginModel/updateState", {
          registerForm: {
            ...registerForm,
            mobile: "",
          },
          loginForm: {
            ...loginForm,
            mobile: "", // 手机号
            password:""
          },
          isShowLogin: false
        });
      },
      style: { width: "380px" },
      content: () => (
        <div>
          {loginState === "login" && <MainComp />}
          {loginState === "reset" && <ResetComp />}
          {loginState === "register" && <RegisterComp />}
          {loginState === "info" && <InfoComp />}
          {loginState === "vxlogin" && <VXLogin />}
          {loginState === "bindPhone" && <BindPhone />}
          {/*<Loading v-show={isShowLoading} />*/}
        </div>
      ),
    };
    return <DialogComp v-show={isShowLogin} data={dialogData} />;
  },
};
</script>
<style lang="less" scoped>
</style>