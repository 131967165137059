import axios from "./request";
import vue from "../main.js";
// 防抖
export const debounce = (fn, delay) => {
  var timer;
  return function() {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};

//生成32位随机数
export const randomNumber =() =>{
	var len = len || 32;
	var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
	var maxPos = chars.length;
	var pwd = '';
	for (var i = 0; i < len; i++) {
		pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	}
	return pwd
}

//获取url地址栏参数
export const GetQueryString =(name) =>{
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
  var context = "";
  if (r != null)
  context = r[2];
  reg = null;
  r = null;
  return context == null || context == "" || context == "undefined" ? "" : context;
}

//去除指定url地址栏参数
export const funcUrlDel =(name) =>{
  var loca = window.location;
  var baseUrl = loca.origin + loca.pathname + "?";
  var query = loca.search.substr(1);
  if (query.indexOf(name)>-1) {
      var obj = {}
      var arr = query.split("&");
      for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
      };
      delete obj[name];
      var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g,"").replace(/\:/g,"=").replace(/\,/g,"&");
      return url
  };
}

//去除地址栏code值
export const urldelCode =() =>{
  let url = window.location.href;
  console.log(url)
  if(url.indexOf("code") >= 0 ) {
    const newUrl = funcUrlDel("code")
    location.replace(newUrl);
  }
}

//获取当前页面地址（去除参数）
export const urlNocanshu =() =>{
  let url = window.location.href;
  console.log(url)
  if(url.indexOf("?") != -1){
    url = url.split("?")[0];
    console.log(url)
  }
  return url
}
//pc端未使用微信登录的标识
export const _makeUID =() =>{
  var s = [];
  var hexDigits = "0123456789ABCDEF";
  for (var i = 0; i < 32; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  // bits 12-15 of the time_hi_and_version field to 0010
  s[12] = "4";
  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[16] = hexDigits.substr((s[16] & 0x3) | 0x8, 1);

  var uuid = s.join("");
  window.$cookies.set("uuid",uuid) 
}
// 接口请求
export const request = (url, params, token) => {
  return axios(url, {
    ...params,
    headers: {
      "X-EICCHANNEL-TOKEN": token,
    },
  });
};

export const upload = (url, params, token) => {
  return axios(url, {
    ...params,
    headers: {
      "X-EICCHANNEL-TOKEN": token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const formatDateTime =(date) =>{
	var date = new Date(date * 1000);
	var YY = date.getFullYear() + "年";
	var MM =
		(date.getMonth() + 1 < 10 ?
			"0" + (date.getMonth() + 1) :
			date.getMonth() + 1) + "月";
	var DD = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
	var hh =
		(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
	var mm =
		date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	var ss =
		date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	return YY + MM + DD // + " " + hh + mm;
}