import * as api from "../../service/index";
import {formatDateTime} from "../../utils/index.js";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    countryList: [], // 国家列表
    typeList: [
      { value: '3,4,5,11', name: "全部" },
      { value: '5', name: "课程" },
      { value: '4', name: "直播" },
      { value: '11', name: "名师" },
      { value: '3', name: "名校" },
    ], // 类别列表

    gradeTypeList: [], // 留学阶段分类

    searchData: {
      keyword: "", // 搜索关键字
      // type: -1, // 类型
      CountryName: "全部", // 国家ID
      Stage: "全部", // 留学阶段
			AssemblyConditions : [{
				BusinessUnit: "SPZ",
				ModuleType: '3,4,5,11',
				ProjectType: "视频站PC",
			}]
    },
    count: 0,
    nowPage: 1,
    limit: 10,
    listData: [],
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取国家列表
    async getCountryList(content, payload) {
      const { succ, results } = await api.getCountryList();
      if (!succ) return;
      const arr = [{ id: -1, name: "全部" }];
      results.map((item) => arr.push({ ...item }));
      content.commit("updateState", {
        countryList: arr,
      });
    },

    // 获取枚举列表
    async getEnumList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getEnumList();
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);

      const gradeTypeList = [{ value: -1, name: "全部" }];
      results
        .filter((item) => item.id === 23)[0]
        .childs.map((item) => gradeTypeList.push(item));

      content.commit("updateState", {
        gradeTypeList,
      });
      return Promise.resolve(msg);
    },

    // 全局搜索
    async siteSearch(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const params = {};
      Object.keys(payload).map((key) => {
        if (payload[key] === "" || payload[key] === "全部") {
          return;
        }
        params[key] = payload[key];
      });
      const { data, succ, msg, count, page } = await api.siteSearch2(params);
      // const { results, succ, msg, count, page } = await api.siteSearch(params);
			// console.log(results, succ, msg, count, page)
      content.commit("updateState", { isLoadingShow: false });
			for(let i in data){
				data[i].BeginTime=formatDateTime(data[i].BeginTime)
			}
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", {
        // listData: results,
        listData: data,
        count,
        // nowPage: Number(page),
      });
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
