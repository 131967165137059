import * as api from "@src/service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    detailData: null, // 详情
    courseList: [], // 相关课程列表
    courseCount: 0, // 相关课程数量

    liveList: [], // 相关直播列表
    liveCount: 0, // 相关直播数量

    teacherList: [], // 相关名师列表
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取名师详情
    async getTeacherDetail(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getTeacherDetail(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { detailData: results });
      return Promise.resolve(msg);
    },
    //添加或取消用户收藏
    async addOrDelUserCollect(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg } = await api.addOrDelUserCollect(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      return Promise.resolve(msg);
    },
    // 获取名师关联的课程列表
    async getTeacherRelevancyCourseList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const {
        succ,
        msg,
        results,
        count,
      } = await api.getTeacherRelevancyCourseList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", {
        courseList: results,
        courseCount: count,
      });
      return Promise.resolve(msg);
    },

    // 获取名师关联的课程列表
    async getTeacherRelevancyLiveList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const {
        succ,
        msg,
        results,
        count,
      } = await api.getTeacherRelevancyLiveList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { liveList: results, liveCount: count });
      return Promise.resolve(msg);
    },

    // 获取相关名师
    async getRelevantTeacherList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, msg, results } = await api.getRelevantTeacherList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { teacherList: results });
      return Promise.resolve(msg);
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
