<script>
import SearchDialog from "./SearchDialog";
import Login from "./Login";
import Loading from "@components/Loading";
export default {
  name: "HeaderComp",
  data() {
    return {
      nowPage: "",
      isShowSearch: false,
      newNoticeCount: 0,
      searchValue: "",
      isShowNav: false,
    };
  },
  async mounted() {
    const { newNoticeCount, userInfo } = this.$store.state.MyModel;
    this.newNoticeCount = newNoticeCount;
    if (window.$cookies.get("token")) {
      try {
        await this.$store.dispatch("MyModel/getUserInfo");
      } catch (error) {
          if (error.code == 1016) {
            window.$cookies.set("token",'')
          }
          return;
        }
    }
    setInterval(async () => {
      if (window.$cookies.get("token")) {
        const num = await this.$store.dispatch("MyModel/getNewNoticeNum", {
          datetime: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        });
        this.newNoticeCount = num;
      }
    }, 1000 * 60 * 5);
    try {
      await this.$store.dispatch("MyModel/getHotKeyList", {
        page: 1,
        pageSize: 10,
      });
    } catch (msg) {
      this.$message({ showClose: true, message: msg, type: "error" });
      return;
    }
    const { hotKeyList } = this.$store.state.MyModel;
    this.searchValue = hotKeyList[0].name;
  },
  methods: {
    toggleNav(url) {
      this.$router.push({ path: url });
      this.nowPage = url;
    },
    onSearchClick() {
      if (localStorage.getItem("historySearch")) {
        const arr = JSON.parse(localStorage.getItem("historySearch"));
        arr.push(this.searchValue);
        localStorage.setItem("historySearch", JSON.stringify(arr));
      } else {
        localStorage.setItem(
          "historySearch",
          JSON.stringify([this.searchValue])
        );
      }
      this.isShowSearch = false;

      this.$router.push({
        path: `/search/result?keyword=${this.searchValue}`,
      });
    },
    oninput(e) {
      const { value } = e.target;
      this.searchValue = value;
    },
    onkeyDown(e) {
      if (e.keyCode == 13) {
        this.onSearchClick();
      }
    },
    async logout() {
      if (this.nowPage === "/my") {
        this.$router.push({ path: `/home` });
      }
      window.$cookies.remove("token");
      this.$store.commit("MyModel/updateState", { userInfo: null });
      this.isShowNav = false;
    },
  },
  watch: {
    $route(to, from) {
      this.nowPage = to.matched[0].path;
    },
  },
  render() {
    const { isLoadingShow, userInfo, hotKeyList } = this.$store.state.MyModel;
    return (
      <div class="headerContainer">
        <div class="headerBox">
          <div class="left">
            <i class="logo" onclick={() => this.toggleNav("/home")} />
            <div class="tabBox">
              <span
                onclick={() => this.toggleNav("/home")}
                class={`tabItem ${
                  this.nowPage === "/home" || this.nowPage === "/"
                    ? "active"
                    : null
                }`}
              >
                首页
              </span>
              <span
                class={`tabItem ${this.nowPage === "/live" ? "active" : null}`}
                onclick={() => this.toggleNav("/live")}
              >
                直播
              </span>
              <span
                class={`tabItem ${this.nowPage === "/class" ? "active" : null}`}
                onclick={() => this.toggleNav("/class")}
              >
                课堂
              </span>
              <span
                class={`tabItem ${this.nowPage === "/my" ? "active" : null}`}
                onclick={() => {
                  if (window.$cookies.get("token")) {
                    this.toggleNav("/my/learnRecord");
                  } else {
                    this.$store.commit("LoginModel/updateState", {
                      isShowLogin: true,
                      loginState: "login",
                    });
                  }
                }}
              >
                我的
              </span>
            </div>
          </div>
          <div class="right">
            <div
              class="searchBox"
              onmouseleave={() => (this.isShowSearch = false)}
            >
              <input
                type="text"
                autocomplete="off"
                name="search"
                onclick={() => (this.isShowSearch = true)}
                oninput={this.oninput}
                onkeydown={this.onkeyDown}
                placeholder={hotKeyList.length ? hotKeyList[0].name : ""}
              />
              <i class="icon-search" onclick={this.onSearchClick} />
              {this.isShowSearch ? (
                <SearchDialog searchclose={() => (this.isShowSearch = false)} />
              ) : null}
            </div>
            {userInfo ? (
              <div class="loginBox">
                <div class="message-box">
                  <i
                    class="icon message"
                    onclick={() =>
                      this.$router.push({
                        path: "/my/message",
                      })
                    }
                  >
                    {this.newNoticeCount ? (
                      <span>{this.newNoticeCount}</span>
                    ) : null}
                  </i>
                  消息
                </div>
                <div
                  class="avatar-box"
                  onmouseover={() => (this.isShowNav = true)}
                  onmouseleave={() => (this.isShowNav = false)}
                >
                  <img
                    src={userInfo.head}
                    onclick={() =>
                      this.$router.push({
                        path: "/my/learnRecord",
                      })
                    }
                  />
                  {this.isShowNav ? (
                    <div class="nav-bar-box">
                      <div class="nav-bar">
                        <div class="list">
                          <router-link to="/my/learnRecord">
                            学习记录
                          </router-link>
                          <router-link to="/my/appointment">
                            我的预约
                          </router-link>
                          <router-link to="/my/collect">我的收藏</router-link>
                          <router-link to="/my/info">个人信息</router-link>
                          <router-link to="/my/order">我的订单</router-link>
                          <router-link to="/my/shop">积分商城</router-link>
                        </div>
                        <span class="logout" onclick={this.logout}>
                          <i class="icon" />
                          登出
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div class="loginBox">
                <span
                  class="loginBtn"
                  onclick={() => {
                    this.$store.commit("LoginModel/updateState", {
                      isShowLogin: true,
                      loginState: "login",
                      loginForm: {
                        countryCode: "", // 国家区号
                        mobile: "", // 手机号
                        code: "", // 验证码
                        password: "", //密码
                      },
                    });
                  }}
                >
                  登录
                </span>
                <span
                  class="registerBtn"
                  onclick={() => {
                    this.$store.commit("LoginModel/updateState", {
                      isShowLogin: true,
                      loginState: "register",
                      registerForm: {
                        mobile: "", // 手机号
                        nickname: "", // 昵称
                        code: "", //验证码
                        password: "", //密码
                        countryId: "", // 国家ID
                        countryCode: "", // 国家区号
                      },
                    });
                  }}
                >
                  注册
                </span>
              </div>
            )}
          </div>
        </div>
        <Login />
        <Loading isShow={isLoadingShow} />
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.headerContainer {
  background: #ffffff;
  .headerBox {
    width: 1100px;
    margin: 0 auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .logo {
        width: 65px;
        height: 45px;
        background-image: url("../assets/images/logo.png");
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
        margin-right: 56px;
        cursor: pointer;
      }
      .tabBox {
        display: flex;
        height: 60px;
        .tabItem {
          display: flex;
          width: 128px;
          align-items: center;
          justify-content: center;
          color: #262626;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          &.active {
            font-weight: bold;
            color: #006eeb;
            background-color: rgba(0, 110, 235, 0.1);
            &::before {
              content: "";
              display: block;
              height: 4px;
              width: 100%;
              background: #006eeb;
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .searchBox {
        margin-right: 50px;
        border: 1px solid #c2c6cc;
        border-radius: 8px;
        padding: 0px 12px;
        width: 280px;
        height: 32px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        position: relative;
        input {
          border: 0;
          outline: none;
          color: #5c6066;
          font-size: 15px;
          width: 100%;
          height: 100%;
        }
        .icon-search {
          width: 15px;
          min-width: 15px;
          height: 16px;
          display: block;
          background-image: url("../assets/images/icon-search.png");
          background-size: 100% 100%;
          cursor: pointer;
        }
      }
      .loginBox {
        display: flex;
        align-items: center;
        .message-box {
          display: flex;
          font-size: 18px;
          font-weight: 400;
          color: #5c6066;
          padding-right: 10px;
          border-right: 1px solid #c2c6cc;
          .icon.message {
            cursor: pointer;
            width: 25px;
            height: 23px;
            background-image: url("../assets/images/message.png");
            position: relative;
            margin-right: 10px;
            span {
              display: block;
              position: absolute;
              width: 16px;
              height: 16px;
              top: -7px;
              right: 2px;
              background: #f15544;
              color: #ffffff;
              font-weight: 400;
              font-size: 9px;
              border-radius: 50%;
              font-style: normal;
              text-align: center;
              line-height: 16px;
            }
          }
        }
        .avatar-box {
          position: relative;
          img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            margin-left: 13px;
            border-radius: 50%;
            cursor: pointer;
          }
          .nav-bar-box {
            position: absolute;
            top: 0px;
            padding-top: 10px;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
            .nav-bar {
              box-shadow: -1px 1px 12px 0px rgba(0, 0, 0, 0.13);
              display: flex;
              flex-direction: column;
              background: #fff;
              border-radius: 8px;
              width: 130px;

              .list {
                border-bottom: 1px solid #ededed;
                padding: 10px 0;
                a {
                  text-align: center;
                  font-size: 14px;
                  font-weight: 400;
                  color: #666666;
                  height: 35px;
                  display: block;
                  line-height: 35px;
                  text-decoration: none;
                }
              }
              .logout {
                line-height: 40px;
                height: 35px;
                color: #999999;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                  width: 14px;
                  height: 14px;
                  background-image: url("../assets/images/logout.png");
                  margin-right: 9px;
                }
              }
            }
          }
        }

        span {
          display: block;
          font-size: 14px;
          color: #1a1a1a;
          cursor: pointer;
        }
        span.loginBtn {
          padding-right: 12px;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            right: 0;
            width: 1px;
            height: 23px;
            background: #a4aab3;
          }
        }
        span.registerBtn {
          padding-left: 12px;
        }
      }
    }
  }
}
</style>
