import * as api from "../../service/index";
export default {
  namespaced: true,
  state: {
    isLoadingShow: false,
    nowPage: 1,
    pageSize: 10,
    count: 0, // 总页数
    lists: [], // 列表数据
    rankingTypeList: [
      { id: -1, name: "全部" },
      { id: 1, name: "QS排名" },
      { id: 2, name: "USNEWS排名" },
      { id: 3, name: "THE排名" },
    ],
    rankingIntervalList: [
      { id: -1, name: "全部" },
      { id: 1, name: "1-50" },
      { id: 2, name: "51-100" },
      { id: 3, name: "101-200" },
      { id: 4, name: "200+" },
    ],
    countryList: [], // 国家列表
    schoolTypeList: [], // 学校分类
    natureTypeList: [], // 学校性质分类
    searchData: {
      keyword: "", // 搜索关键字
      categoryId: -1, // 院校分类ID
      countryId: -1, // 国家ID
      nature: -1, // 学校性质
      rankingType: -1, // 排名Id
      rankingInterval: -1, // 排名区间
    },
  },
  mutations: {
    updateState(state, payload) {
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    // 获取院校列表
    async getSchoolList(content, payload) {
      Object.keys(payload).map(
        (key) => (payload[key] = payload[key] === -1 ? "" : payload[key])
      );
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, count, page, msg } = await api.getSchoolList(
        payload
      );
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", {
        lists: results,
        count,
        nowPage: Number(page),
      });
      return Promise.resolve();
    },
    // 获取国家
    async getCountryList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getCountryList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      const arr = [{ id: -1, name: "全部" }];
      results.map((item) => arr.push({ ...item }));
      content.commit("updateState", { countryList: arr });
      return Promise.resolve();
    },

    // 获取枚举
    async getEnumList(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { succ, results, msg } = await api.getEnumList(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);

      const schoolType = results.filter((item) => item.id === 52)[0].childs;
      const natureType = results.filter((item) => item.id === 159)[0].childs;

      const schoolTypeArr = [{ value: -1, name: "全部" }];
      const natureTypeArr = [{ value: -1, name: "全部" }];

      schoolType.map((item) => schoolTypeArr.push({ ...item }));
      natureType.map((item) => natureTypeArr.push({ ...item }));
      content.commit("updateState", {
        schoolTypeList: schoolTypeArr,
        natureTypeList: natureTypeArr,
      });
      return Promise.resolve();
    },
    //全部页面埋点
    async recordAllPage(content, payload) {
      content.commit("updateState", { isLoadingShow: true });
      const { results, succ, msg } = await api.recordAllPage(payload);
      content.commit("updateState", { isLoadingShow: false });
      if (!succ) return Promise.reject(msg);
      content.commit("updateState", { recordAllPage: '' });
      return Promise.resolve();
    },
  },
};
