import * as api from "../../service/index";
export default {
    namespaced: true,
    state: {
        isLoadingShow: false,
        updatePhoneShow: false,
        updateEmailShow: false,
        infoState: "info", // info 显示个人信息页  address 显示地址页
        orderState: "list", // list 显示订单列表  detail 显示订单详情
        orderDetailNumber: null, // 订单号（用于跳转至详情id）

        integralState: "shop", // shop显示积分商城 order 显示积分订单列表  detail 显示积分商品详情  shopDetail 显示商品详情  confirmOrder 确认订单
        integralId: null, // 积分商品详情id(用于跳转至详情id)
        integralDetail: null,
        shopListFilter: {
            currentPage: 1,
            tabActive: 0,
            min: null,
            max: null,
            shopDetailBack: false, //商品详情页返回列表页是否返回商品所在的页数
        },

        baseInfoData: {},

        subLiveList: [], //用户预约直播列表
        subLiveListCount: 0, // 用户预约直播列表数量

        noticeList: [], // 消息列表
        noticeListCount: 0, // 消息列表数量

        myCollectList: [], // 我的收藏列表
        myCollectListCount: 0, // 我的收藏列表数量

        learnTimeData: null, // 学习时常
        isSign: false,


        prizeList: [], // 奖品列表
        prizeListCount: 0, // 奖品列表总数

        bookList: [], // 书籍列表(报告获取)
        bookListCount: 0, //书籍列表(报告获取) 总数

        isViewReport: false,

        qrCode: "", // 获取二维码图片base64二进制代码

        hotKeyList: [], //热门关键词列表

        learnRecordList: [], // 学习记录列表
        learnRecordListCount: 0, // 学习记录总数

        orderList: [], // 订单列表
        orderListCount: 0, // 订单总数
        orderDetailData: {}, // 订单详情

        shopBannerList: [], // 积分商城banner图列表
        integralShopList: [], // 积分商城列表
        integralShopCount: 0, // 积分商城总数
        integralShopOrderList: [], // 积分商城订单列表
        integralShopOrderCount: 0, // 积分商城订单总数
        integralShopDetail: null, // 积分商城订单详情
        integralShopDetailId: null, // 积分商城订单详情Id(跳转详情用)

        taskList: [], // 积分任务列表
        scoreRecordList: [], // 积分记录列表

        userInfo: null, // 用户信息
        statusToken:null,//token是否过期
        couponList: [], // 优惠券列表
        couponCount: 0, // 优惠券总数

        countryList: [], //国家列表
        eduStateList: [], // 教育程度列表
        gradeList: [], // 留学阶段列表

        userAddressList: [], //用户地址列表

        relevantCourseList: [], // 相关课程列表
        relevantLiveList: [], // 相关直播列表
        relevantGoodList: [], // 相关商品列表

        baseInfoForm: {
            nickname: "", // 昵称
            signature: "", // 个性签名
            mobile: "", // 手机号
            countryId: "", // 国家ID
            email: "", // 邮箱地址
            eduStatusId: "", //教育程度
            realName: "", // 真实姓名
            region: "", // 所在区域
            gradeId: "", // 现阶段(留学阶段id)
        },
        regionList: [], // 所在区域信息列表
    },
    mutations: {
        updateState(state, payload) {
            Object.keys(payload).map((key) => {
                state[key] = payload[key];
            });
        },
    },
    actions: {
        // 获取用户预约直播列表
        async getUserSubLiveList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getUserSubLiveList(
                payload
            );
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                subLiveList: results,
                subLiveListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取用户消息列表
        async getNoticeList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getNoticeList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                noticeList: results,
                noticeListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取用户收藏列表
        async getMyCollectList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getMyCollectList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                myCollectList: results,
                myCollectListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取最新消息数量
        async getNewNoticeNum(content, payload) {
            const { succ, results, msg } = await api.getNewNoticeNum(payload);
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(results.num);
        },

        //获取用户学习时长
        async getUserLearnTime(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getUserLearnTime(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { learnTimeData: results });
            return Promise.resolve(results.num);
        },
        //判断用户是否签到
        async isSign(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.isSign(payload);
            content.commit("updateState", { isLoadingShow: false });
            content.commit("updateState", { isSign: succ });
        },
        // 用户签到
        async userSign(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.userSign(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { isSign: succ });
            return Promise.resolve(msg);
        },

        // 获取我的奖品列表
        async getMyPrizeList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getMyPrizeList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                prizeList: results,
                prizeListCount: count,
            });
            return Promise.resolve(results.num);
        },

        // 获取热门关键词列表
        async getHotKeyList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getHotKeyList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { hotKeyList: results });
            return Promise.resolve(results.num);
        },

        // 获取书籍列表
        async getBookList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getBookList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                bookList: results,
                bookListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取书籍浏览权限
        async getBookDetail(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getBookDetail(payload);
            content.commit("updateState", { isLoadingShow: false });
            content.commit("updateState", { isViewReport: succ });
        },

        // 获取弹出层二维码图片的Base64代码
        async getUrlQRCode(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getUrlQRCode(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { qrCode: results });
            return Promise.resolve(msg);
        },

        // 获取所在区域省市区信息列表
        async getAreaList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getAreaList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { regionList: results });
            return Promise.resolve(msg);
        },

        // 获取学习记录
        async getUserLearnRecord(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getUserLearnRecord(
                payload
            );
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                learnRecordList: results,
                learnRecordListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取用户订单列表
        async getUserOrderList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getUserOrderList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                orderList: results,
                orderListCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取订单详情
        async getOrderDetail(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getOrderDetail(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { orderDetailData: results });
            return Promise.resolve(msg);
        },

        // 获取用户信息
        async getUserInfo(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { code,succ, results, msg } = await api.getUserInfo(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject({ msg, code });
            content.commit("updateState", { userInfo: results });
            return Promise.resolve(msg);
        },

        // 获取积分任务列表
        async getTaskList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getTaskList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { taskList: results });
            return Promise.resolve(msg);
        },

        // 获取积分记录列表
        async getScoreRecordList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getScoreRecordList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { scoreRecordList: results });
            return Promise.resolve(msg);
        },

        // 上传用户头像
        async upUserHead(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.upUserHead(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 获取商品列表
        async getGoodsList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getGoodsList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                integralShopList: results,
                integralShopCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取积分商品详情
        async getGoodsDetail(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getGoodsDetail(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                integralDetail: results,
            });
            return Promise.resolve(msg);
        },

        // 领取积分
        async getTaskScore(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getTaskScore(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);

            return Promise.resolve(msg);
        },

        // 获取优惠券
        async getCouponList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getCouponList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                couponList: results,
                couponCount: count,
            });
            return Promise.resolve(msg);
        },

        // 兑换优惠券
        async exchangeCoupon(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.exchangeCoupon(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 获取国家列表
        async getCountryList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.getCountryList();
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                countryList: results,
            });
            return Promise.resolve(msg);
        },

        // 获取用户地址列表
        async getUserAddressList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.getUserAddressList();
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                userAddressList: results,
            });
            return Promise.resolve(msg);
        },

        // 添加或修改用户地址
        async addOrUpUserAddress(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.addOrUpUserAddress(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 删除用户地址
        async delUserAddress(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.delUserAddress(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 获取相关课程
        async getRelevantCourseList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getRelevantCourseList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { relevantCourseList: results });
            return Promise.resolve();
        },

        // 获取相关直播
        async getRelevantLiveList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getRelevantLiveList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { relevantLiveList: results });
            return Promise.resolve();
        },

        // 获取枚举列表
        async getEnumList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getEnumList();
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);

            const eduStateList = [];
            const gradeList = [];
            results
                .filter((item) => item.id === 15)[0]
                .childs.map((item) => eduStateList.push(item));
            results
                .filter((item) => item.id === 23)[0]
                .childs.map((item) => gradeList.push(item));

            content.commit("updateState", { eduStateList, gradeList });
            return Promise.resolve(msg);
        },

        // 取消用户订单
        async cancleOrder(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.cancleOrder(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 修改用户手机号
        async upUserMobile(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.upUserMobile(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 完善用户信息
        async completeUserInfo(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.completeUserInfo(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 修改个人信息
        async updateUserInfo(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.upUserInfo(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(msg);
        },

        // 创建积分商城订单
        async createShopOrder(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.createShopOrder(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            return Promise.resolve(results);
        },

        // 获取积分商城订单列表
        async getShopOrderList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getShopOrderList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                integralShopOrderList: results,
                integralShopOrderCount: count,
            });
            return Promise.resolve(msg);
        },

        // 获取积分商城订单详情
        async getShopOrderDetail(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg, count } = await api.getShopOrderDetail(
                payload
            );
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                integralShopDetail: results,
            });
            return Promise.resolve(msg);
        },

        // 获取相关商品
        async getRelevantGoodList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getRelevantGoodList(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", {
                relevantGoodList: results,
            });
            return Promise.resolve(msg);
        },

        // 获取板块内容
        async getPageInfoList(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { succ, results, msg } = await api.getPageInfoList({ id: 294 });
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            const shopBannerList = [];
            results.pageInfo.map(
                (item) => item.pageInfoTypeId === 296 && shopBannerList.push(item)
            );
            content.commit("updateState", {
                shopBannerList,
            });
            return Promise.resolve(msg);
        },

        // 获取物流信息
        async getOrderExpress(content, payload) {
            // content.commit("updateState", { isLoadingShow: true });
            return await api.getOrderExpress(payload);
            // content.commit("updateState", { isLoadingShow: false });
            // if (!succ) return Promise.reject(msg);
            // return Promise.resolve(results);
        },

        //全部页面埋点
        async recordAllPage(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.recordAllPage(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { recordAllPage: '' });
            return Promise.resolve();
        },

        //用户行为埋点
        async recordBehavior(content, payload) {
            content.commit("updateState", { isLoadingShow: true });
            const { results, succ, msg } = await api.recordBehavior(payload);
            content.commit("updateState", { isLoadingShow: false });
            if (!succ) return Promise.reject(msg);
            content.commit("updateState", { recordBehavior: '' });
            return Promise.resolve();
        },
    },
};